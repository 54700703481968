/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box,
  Button,
  Grid,
  makeStyles,
  AppBar,
  Tab,
  Tabs,
  Chip,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import {
  React, useContext, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { useProject } from '../../../config/ProjectContext';
import SelectedObjectsToDeployContext from '../../../config/SelectedAssetsContext';
import GraphqlService from '../../../service/graphqlService';
import PageBanner from '../../utils/PageBanner';
import GlobalDeploy from './GlobalDeploy';
import MachineDeploy from './MachineDeploy';
import RegexDeploy from './RegexDeploy';
import MarketDeploy from './MarketDeploy';
import NewDeployHeader from './NewDeployHeader';
import PrimaryButton from '../../utils/PrimaryButton';
import YesNoDialog from '../../utils/YesNoDialog';
import {
  CREATE_SERIAL_DEPLOY_PERMISSION,
  CREATE_MARKET_DEPLOY_PERMISSION,
  CREATE_REGEX_DEPLOY_PERMISSION,
  CREATE_GLOBAL_DEPLOY_PERMISSION,
  DEPLOY_TYPE_GLOBAL,
  DEPLOY_TYPE_REGEX,
  VALIDATION_REGEX_MANDATORY,
  VALIDATION_MAX_1_REGEX,
  VALIDATION_LENGTH_MAX_REGEX,
  DEPLOY_TYPE_MARKET,
  VALIDATION_MARKET_MANDATORY,
  DEPLOY_TYPE_SERIAL,
} from '../../../utils/constants';
import TabPanel, { a11yProps } from '../../TabPanel';
import Restricted from '../../../authorization/Restricted';
import usePermission from '../../../authorization/permissionHook';
import { useSnackbar } from '../../../providers/SnackbarContext';
import DeploymentResultPopup from '../../DeploymentResultPopup/DeploymentResultPopup';
import ErrorPopup from '../../ErrorPopup/ErrorPopup';
import useGetDevicesNotAllowed from '../../../hooks/useGetDevicesNotAllowed';

const useStyles = makeStyles((theme) => ({
  formContent: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    width: '100%',
  },
  formField: {
    width: '100%',
  },
  button: {
    marginLeft: theme.spacing(3),
    borderRadius: '12px',
  },
  inputLabel: {
    fontFamily: theme.typography.h3.fontFamily,
    fontWeight: theme.typography.h3.fontWeight,
    fontSize: theme.typography.h3.fontSize,
    lineHeight: theme.typography.h3.lineHeight,
    letterSpacing: theme.typography.h3.letterSpacing,
    color: 'white',
    paddingBottom: '8px',
  },
  divider: {
    height: '1px',
    width: '95.5%',
    backgroundColor: theme.palette.primary.subMain,
    marginLeft: '24px',
  },
  headerTabPanel: {
    height: 48,
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.subMain,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '16px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: '0',
    lineHeight: '19px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
    height: '4px',
  },
  numSerialsLabel: {
    paddingLeft: '10px',
    color: theme.palette.primary.text,
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: '13px',
    fontStyle: 'italic',
    letterSpacing: '0',
    lineHeight: '19px',
    textAlign: 'center',
    fontWeight: theme.typography.fontWeightRegular,
  },
  buttonDeploy: {
    marginRight: '24px',
    marginBottom: 18,
  },
  containerButtons: {
    marginTop: '24px',
    marginRight: '24px',
    marginLeft: '66px',
  },
  tabContent: {
    backgroundColor: theme.palette.background.default,
  },
  chipTitle: {
    color: theme.palette.primary.text,
    fontSize: 16,
    marginRight: 16,
    marginLeft: 24,
  },
  chip: {
    background: theme.palette.background.paper,
    color: theme.palette.secondary.contrastText,
  },
  uploadedFiles: {
    padding: 24,
  },
}));

export default function NewDeploy() {
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');
  //  state values
  const [submitting, setSubmitting] = useState(false);

  // PERMISSIONS___________________________
  const [loadingDeviceDeployment, allowedSerialDeployment] = usePermission(CREATE_SERIAL_DEPLOY_PERMISSION);
  const [loadingMarketDeployment, allowedMarketDeployment] = usePermission(CREATE_MARKET_DEPLOY_PERMISSION);
  const [loadingRegexDeployment, allowedRegexDeployment] = usePermission(CREATE_REGEX_DEPLOY_PERMISSION);
  const [loadingGlobalDeployment, allowedGlobalDeployment] = usePermission(CREATE_GLOBAL_DEPLOY_PERMISSION);

  const [targets, setTargets] = useState([]);
  const [type, setType] = useState();
  const [myFiles, setMyFiles] = useState([]);
  const [regex, setRegex] = useState([]);
  const [market, setMarket] = useState('');
  const [selectedMarkets, setSelectedMarkets] = useState([]);

  const [valueTab, setValueTab] = useState(0);
  const [valuesMachineDeploy, setValuesMachineDeploy] = useState('');
  const [valuesRegexDeploy, setValuesRegexDeploy] = useState('');
  const [valuesMarketDeploy, setValuesMarketDeploy] = useState('');
  const [valuesGlobalDeploy, setValuesGlobalDeploy] = useState('');
  const [selectedExcludedValue, setSelectedExcludedValue] = useState(null);
  const [excludedValues, setExcludedValues] = useState(null);

  const [yesNoDialogOpen, setYesNoDialogOpen] = useState(false);
  const [yesNoDialogText, setYesNoDialogText] = useState('');
  const { selectedObjectsToDeploy } = useContext(SelectedObjectsToDeployContext);

  const [popupOpen, setPopupOpen] = useState(false);
  const [deployResultData, setDeployResultData] = useState({});

  const { areAllowed: devicesNotAllowed, isLoading: isLoadingDevicesAllowed } = useGetDevicesNotAllowed(targets);

  const history = useHistory();

  const openSnackBar = useSnackbar();

  const { project } = useProject();

  function openErrorPopup(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // DATA RETRIEVAL __________________________

  // FORM_________________________________
  const {
    handleSubmit, formState: { errors },
  } = useForm();

  function validRegexDeployment(targetsOption) {
    if (targetsOption.length === 0) {
      openErrorPopup('Incomplete form', VALIDATION_REGEX_MANDATORY);
      return false;
    }
    if (targetsOption.length > 1) {
      openErrorPopup('Incomplete form', VALIDATION_MAX_1_REGEX);
      return false;
    }
    if (targetsOption[0].length > 300) {
      openErrorPopup('Incomplete form', VALIDATION_LENGTH_MAX_REGEX);
      return false;
    }
    return true;
  }

  function validMarketDeployment(targetsOption) {
    if (targetsOption.length === 0) {
      openErrorPopup('Incomplete form', VALIDATION_MARKET_MANDATORY);
      return false;
    }
    return true;
  }

  function getTargetForGlobalType(excludedValue) {
    return (excludedValue !== 'EXCLUDE-MARKET') ? '*' : undefined;
  }

  function getExcludeForGlobalType(excludedValue, values) {
    return (excludedValue === 'EXCLUDE-SERIAL') ? values : undefined;
  }

  function getTargetMarketForGlobalType(excludedValue) {
    return (excludedValue === 'EXCLUDE-MARKET') ? '*' : undefined;
  }

  function getExcludedMarket(excludedValue, values) {
    return (excludedValue === 'EXCLUDE-MARKET') ? values : undefined;
  }

  async function deployGlobalType(iProject, comments, typeDeploy, targetsDeploy) {
    try {
      const fileIds = selectedObjectsToDeploy.map((asset) => asset.fileID).filter((fileId) => fileId != null);
      const releaseID = selectedObjectsToDeploy?.length > 0 ? selectedObjectsToDeploy[0].releaseID : null;

      const creationResponse = await GraphqlService.createGlobalDeploy(
        iProject.code,
        fileIds,
        releaseID,
        typeDeploy,
        targetsDeploy,
        comments,
        getTargetForGlobalType(selectedExcludedValue),
        getTargetMarketForGlobalType(selectedExcludedValue),
        getExcludeForGlobalType(selectedExcludedValue, excludedValues),
        getExcludedMarket(selectedExcludedValue, excludedValues),
      );

      setDeployResultData({
        deployID: creationResponse.deployID,
        waveId: creationResponse.waveId,
        deployIDS: creationResponse.deployIDS,
        newDeployIDs: creationResponse.newDeployIDs,
        alreadyExistingDeployIDs: creationResponse.alreadyExistingDeployIDs,
      });

      setPopupOpen(true);
    } catch (err) {
      console.log('deployGlobalType err', err.toString());
      // openSnackBar(err, 'error');
    }
  }

  async function deployRegexType(iProject, comments, typeDeploy, targetsDeploy) {
    const fileIds = selectedObjectsToDeploy.map((asset) => asset.fileID).filter((fileId) => fileId !== null);
    const releaseID = selectedObjectsToDeploy?.length > 0 ? selectedObjectsToDeploy[0].releaseID : null;
    if (validRegexDeployment(targetsDeploy)) {
      try {
        const creationResponse = await GraphqlService.createRegexDeploy(iProject.code, fileIds, releaseID, typeDeploy, targetsDeploy, comments);
        setDeployResultData({
          deployID: creationResponse.deployID,
          waveId: creationResponse.waveId,
          deployIDS: creationResponse.deployIDS,
          newDeployIDs: creationResponse.newDeployIDs,
          alreadyExistingDeployIDs: creationResponse.alreadyExistingDeployIDs,
        });
        setPopupOpen(true);
      } catch (err) {
        console.log('deployRegexType err', err);
      }
    }
  }

  async function deployMarketType(iProject, comments, typeDeploy, targetsDeploy) {
    const fileIds = selectedObjectsToDeploy.map((asset) => asset.fileID).filter((fileId) => fileId != null);
    const releaseID = selectedObjectsToDeploy?.length > 0 ? selectedObjectsToDeploy[0].releaseID : null;
    if (validMarketDeployment(targetsDeploy)) {
      try {
        const creationResponse = await GraphqlService.createMarketDeploy(iProject.code, fileIds, releaseID, typeDeploy, targetsDeploy, comments);
        setDeployResultData({
          deployID: creationResponse.deployID,
          waveId: creationResponse.waveId,
          deployIDS: creationResponse.deployIDS,
          newDeployIDs: creationResponse.newDeployIDs,
          alreadyExistingDeployIDs: creationResponse.alreadyExistingDeployIDs,
        });
        setPopupOpen(true);
      } catch (err) {
        openSnackBar(`Error  deploying: ${err?.toString()}`, 'error');
      }
    }
  }

  useEffect(() => {
    console.log('deployResultData', deployResultData);
    setPopupOpen(
      (deployResultData.deployID != null || (deployResultData.deployIDS != null && deployResultData.deployIDS.length > 0) || deployResultData.waveId != null),
    );
  }, [deployResultData]);

  async function deviceDeployment(iProject, comments, typeDeploy, targetsDeploy) {
    const fileIds = selectedObjectsToDeploy.map((asset) => asset.fileID).filter((fileId) => fileId != null);
    const releaseID = selectedObjectsToDeploy?.length > 0 ? selectedObjectsToDeploy[0].releaseID : null;
    try {
      const creationResponse = await GraphqlService.createSerialDeploy(iProject.code, fileIds, releaseID, typeDeploy, targetsDeploy, comments);
      setDeployResultData({
        deployID: creationResponse.deployID,
        waveId: creationResponse.waveId,
        deployIDS: creationResponse.deployIDS,
        newDeployIDs: creationResponse.newDeployIDs,
        alreadyExistingDeployIDs: creationResponse.alreadyExistingDeployIDs,
      });
    } catch (err) {
      openSnackBar(`Error  deploying: ${err?.toString()}`, 'error');
    }
  }

  async function save(iProject, comments, typeDeploy, targetsDeploy) {
    try {
      setSubmitting(true);
      if (typeDeploy === '') {
        openErrorPopup('Incomplete form', 'You must choose a target to be deployed');
      } else if (typeDeploy === DEPLOY_TYPE_GLOBAL) {
        deployGlobalType(iProject, comments, typeDeploy, targetsDeploy);
      } else if (typeDeploy === DEPLOY_TYPE_REGEX) {
        deployRegexType(iProject, comments, typeDeploy, targetsDeploy);
      } else if (typeDeploy === DEPLOY_TYPE_MARKET) {
        deployMarketType(iProject, comments, typeDeploy, targetsDeploy);
      } else if (typeDeploy === DEPLOY_TYPE_SERIAL) {
        if (devicesNotAllowed?.length > 0) {
          throw new Error(`The following devices are not allowed: ${devicesNotAllowed.join(', ')}`);
        }
        deviceDeployment(iProject, comments, typeDeploy, targetsDeploy);
      }
    } catch (error) {
      openErrorPopup('Error', `ERROR - deployment failed. ${error.message}`);
    }
    setSubmitting(false);
  }

  function checkDisableStatus() {
    switch (valueTab) {
      case 0:
        return (targets.length === 0);
      case 1:
        return (regex.length === 0);
      case 2:
        return (selectedMarkets.length === 0);
      default:
        // global
        return false;
    }
  }

  const getValues = () => {
    switch (valueTab) {
      case 0:
        return valuesMachineDeploy;
      case 1:
        return valuesRegexDeploy;
      case 2:
        return valuesMarketDeploy;
      default:
        // global
        return valuesGlobalDeploy;
    }
  };

  const onSubmit = (data) => {
    if (type === DEPLOY_TYPE_GLOBAL) {
      setYesNoDialogText('Are you sure you want to launch a global deploy?');
      setYesNoDialogOpen(true);
      return;
    }
    save(project, getValues(), type, targets);
  };

  const handleClose = () => {
    setPopupOpen(false);
    history.push('/waves');
  };

  // WEBPAGE______________________________
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };

  const classes = useStyles();

  const renderMyFiles = () => {
    if (myFiles.length > 0 && targets.length > 0) {
      return (
        <Grid item container alignItems="center" direction="row" className={classes.uploadedFiles}>
          <Chip
            className={classes.chip}
            label={myFiles[0].name}
            variant="outlined"
            color="primary"
            id="chipNewDeploymentFile"
            onDelete={() => setMyFiles('')}
          />
          <Typography className={classes.numSerialsLabel} component="div">
            {targets.length}
            &nbsp;serial numbers uploaded
          </Typography>
        </Grid>
      );
    }
    return ' ';
  };

  return (
    <Box className={classes.tabContent}>
      <YesNoDialog
        open={yesNoDialogOpen}
        setOpen={setYesNoDialogOpen}
        header="Global deployment"
        body={yesNoDialogText}
        action={() => save(project, getValues(), type, targets)}
        actionName="Deploy"
      />
      <DeploymentResultPopup
        open={popupOpen}
        onClose={handleClose} // Close the popup when needed
        deployResultData={deployResultData}
      />
      <ErrorPopup
        open={alertOpen}
        onClose={() => setAlertOpen(false)}
        title={alertHeader}
        description={alertText}
      />
      <PageBanner title="CREATE DEPLOYMENT" />
      <form onSubmit={handleSubmit(onSubmit)} className={classes.tabContent}>
        <NewDeployHeader objectsToDeploy={selectedObjectsToDeploy} />

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
        >
          <Grid
            item
            xs={12}
            direction="column"
            container
          >
            <AppBar position="static" elevation={0}>
              <Tabs value={valueTab} aria-label="simple tabs example" onChange={handleChange} centered className={classes.headerTabPanel} classes={{ indicator: classes.indicator }}>
                <Tab id="tabMachineDeployment" label="DEVICE DEPLOYMENT" {...a11yProps(0)} className={classes.labelHeaderTabPanel} disabled={!allowedSerialDeployment} />
                <Tab id="tabRegexDeployment" label="REGEX DEPLOYMENT" {...a11yProps(1)} className={classes.labelHeaderTabPanel} disabled={!allowedRegexDeployment} />
                <Tab id="tabMarketDeployment" label="MARKET DEPLOYMENT" {...a11yProps(2)} className={classes.labelHeaderTabPanel} disabled={!allowedMarketDeployment} />
                <Tab id="tabGlobalDeployment" label="GLOBAL DEPLOYMENT" {...a11yProps(3)} className={classes.labelHeaderTabPanel} disabled={!allowedGlobalDeployment} />
              </Tabs>
            </AppBar>
            <Restricted to={[CREATE_SERIAL_DEPLOY_PERMISSION]}>
              <TabPanel value={valueTab} index={0} className={classes.tabContent}>
                <MachineDeploy targets={targets} setTargets={setTargets} setType={setType} setMyFiles={setMyFiles} values={valuesMachineDeploy} setValues={setValuesMachineDeploy} renderMyFiles={renderMyFiles} />
              </TabPanel>
            </Restricted>
            <Restricted to={[CREATE_REGEX_DEPLOY_PERMISSION]}>
              <TabPanel value={valueTab} index={1} className={classes.tabContent}>
                <RegexDeploy setTargets={setTargets} setType={setType} values={valuesRegexDeploy} setValues={setValuesRegexDeploy} regex={regex} setRegex={setRegex} />
              </TabPanel>
            </Restricted>
            <Restricted to={[CREATE_MARKET_DEPLOY_PERMISSION]}>
              <TabPanel value={valueTab} index={2} className={classes.tabContent}>
                <MarketDeploy setTargets={setTargets} setType={setType} values={valuesMarketDeploy} setValues={setValuesMarketDeploy} market={market} setMarket={setMarket} selectedMarkets={selectedMarkets} setSelectedMarkets={setSelectedMarkets} />
              </TabPanel>
            </Restricted>
            <Restricted to={[CREATE_GLOBAL_DEPLOY_PERMISSION]}>
              <TabPanel value={valueTab} index={3} className={classes.tabContent}>
                <GlobalDeploy
                  setType={setType}
                  project={project}
                  values={valuesGlobalDeploy}
                  setValues={setValuesGlobalDeploy}
                  selectedExcludedValue={selectedExcludedValue}
                  setSelectedExcludedValue={setSelectedExcludedValue}
                  excludedValues={excludedValues}
                  setExcludedValues={setExcludedValues}
                />
              </TabPanel>
            </Restricted>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          className={classes.tabContent}
        >
          <Grid
            item
            container
            direction="row-reverse"
            className={classes.containerButtons}
          >
            <Grid item xs={2} className={classes.buttonDeploy}>
              <PrimaryButton
                type="submit"
                variant="contained"
                color="primary"
                disabled={checkDisableStatus()}
                id="btnDeployment"
              >
                Deploy
              </PrimaryButton>
            </Grid>
            <Grid item>
              <Button
                type="button"
                variant="contained"
                color="default"
                disabled={submitting}
                onClick={history.goBack}
                className={classes.button}
                id="btnCancelDeployment"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
