import { useQuery } from '@tanstack/react-query';
import { useProject } from '../config/ProjectContext';
import GraphqlService from '../service/graphqlService';
import hasToValidateRole from '../utils/hasToValidateRole';

const useIsDevicePermissionAllowed = (device) => {
  const { project: { code, role } } = useProject();

  const getDevicesPermissionsByProject = async () => GraphqlService.getDevicesPermissionsByProject(code);

  const { data: devicePermissions, isLoading, isFetching } = useQuery(
    ['devicesPermissions', code],
    getDevicesPermissionsByProject,
    {
      enabled: !!code, // Ensure query runs only when the code is available
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  );

  const isAllowedFunc = () => {
    if (!hasToValidateRole(role)) {
      return true;
    }

    if (isLoading || isFetching) {
      return false; // While loading, assume false to prevent errors
    }

    if (devicePermissions?.includes('*')) {
      return true;
    }

    return devicePermissions?.includes(device) || false;
  };

  const isAllowed = isAllowedFunc();

  return { isAllowed, isLoading, isFetching };
};

export default useIsDevicePermissionAllowed;
