/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, {
  useEffect, useState, useMemo, useRef,
} from 'react';
import {
  makeStyles,
  Typography,
  InputLabel,
  Box,
  LinearProgress,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  Stepper,
  Step,
  StepLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';
import { useQuery, useMutation } from '@tanstack/react-query';
import { motion } from 'framer-motion';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CachedIcon from '@material-ui/icons/Cached';
import InfoIcon from '@material-ui/icons/Info';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import moment from 'moment';
import MomentUtils from '@date-io/moment';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import ProgressDialog from '../../components/utils/ProgressDialog';
import MessageCard from '../../components/utils/MessageCard';
import { useSnackbar } from '../../providers/SnackbarContext';
import PageBanner from '../../components/utils/PageBanner';
import { stringHasValue } from '../../utils/stringHasValue';
import TabPanel, { a11yProps } from '../../components/TabPanel';
import RequestManager from './RequestManager';

const useStyles = makeStyles((theme) => ({
  table: {
    marginTop: theme.spacing(2),
  },
  headerCheckbox: {
    color: theme.palette.common.black,
    zIndex: 1000,

  },
  container: {
    padding: theme.spacing(3),
  },
  stepContent: {
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginRight: 280,
    },
  },
  mainContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  assetDetailTitleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardTitle: {
    fontSize: 16,
    whiteSpace: 'normal',
    wordBreak: 'break-word',
  },
  cardHeader: {
    padding: 4,
  },
  cardTopHeader: {
    padding: 0,
    paddingLeft: 2,
    marginBottom: -28,
  },
  cardContent: {
    backgroundColor: 'white',
    padding: 8,
    height: '100%',
    boxShadow: 'none',
    border: 'none',
  },
  detailIcon: {
    // size of the icon has to be smaller than the row height
    padding: 0,
  },
  selectedItems: {
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  button: {
    marginLeft: theme.spacing(1),
  },

  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  wizardContainer: {
    padding: theme.spacing(3),
    position: 'relative',
  },
  stepWizardContent: {
    padding: theme.spacing(2),
  },
  stepLabel: {
    cursor: 'pointer',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  highlightArrow: {
    position: 'absolute',
    zIndex: 2000,
    color: theme.palette.primary.main,
  },
  highlightText: {
    position: 'absolute',
    width: 600,
    backgroundColor: 'transparent', // Set background to transparent
    color: 'orange',
    // bold
    fontWeight: 'bold',
  },
  infoFormfUncomplete: {
    color: 'red',
    fontSize: 14,
  },
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    alignItems: 'flex-end',
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
  },
  selectSRFfunctions: {
    height: 56,
    width: '100%',
  },
  selectIssuer: {
    width: '100%',
    alignSelf: 'center',
    marginTop: 28,
  },
  grayLabel: {
    color: 'gray', // or any specific gray shade you prefer
  },
  datePickerRoot: {
    '& .MuiFormControl-root .MuiInputBase-root': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .MuiFormControl-root .MuiInputAdornment-root': {
      marginRight: theme.spacing(2),
    },
  },

  inputAdornment: {
    marginRight: theme.spacing(19), // Use theme spacing for consistency
  },
  scheduledTab: {
    backgroundColor: 'orange',
    color: 'black',
    fontWeight: 'bold',
    padding: '10px',
    borderRadius: '5px',
    display: 'inline-block',
    marginTop: '10px',
  },
  okTab: {
    backgroundColor: 'green',
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    borderRadius: '5px',
    display: 'inline-block',
    marginTop: '10px',
  },
  executingTab: {
    backgroundColor: 'blue',
    color: 'white',
    fontWeight: 'bold',
    padding: '10px',
    borderRadius: '5px',
    display: 'inline-block',
    marginTop: '10px',
  },
  regularTab: {
    backgroundColor: 'transparent',
    color: '#000',
    padding: '10px',
    borderRadius: '5px',
    display: 'inline-block',
    marginTop: '10px',
  },
  disabledTab: {
    color: theme.palette.text.disabled,
  },
  expirationTimePicker: {
    marginTop: 16,
  },
  issuedTimePicker: {
    marginTop: 8,
  },
  expirationPickers: {
    marginTop: -8,
  },
}));

export default function CloudToDevice() {
  const classes = useStyles();

  /** USESTATE DEFS */
  /** COMMON */
  const { project } = useProject();
  const [activeStep, setActiveStep] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);

  /** STEP 1 */
  const [deviceSerial, setDeviceSerial] = useState('');
  const [securityMode, setSecurityMode] = useState(null);
  const [SRFfunction, setSRFfunction] = useState('');

  /** STEP 2 */
  const [expirationTime, setExpirationTime] = useState(moment().format('HH:mm'));
  // expiration date set with 1 day ahead by default
  const [expirationDate, setExpirationDate] = useState(moment().add(1, 'days'));
  const [issuer, setIssuer] = useState('');
  const [issuedAtDate, setIssuedAtDate] = useState(null);
  const [issuedAtTime, setIssuedAtTime] = useState(null);
  const [privateKey, setPrivateKey] = useState('');
  const [privateKeyAlgorithm, setPrivateKeyAlgorithm] = useState('');
  const [numberOfRetries, setNumberOfRetries] = useState('');
  const [optionalParams, setOptionalParams] = useState('');
  const [payloadPreview, setPayloadPreview] = useState('');
  const [optionalParamsError, setOptionalParamsError] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  /** STEP 3 */
  const [mutationResult, setMutationResult] = useState(null);
  const [responseTabValue, setResponseTabValue] = useState(0);
  /** REACT QUERY */
  /** QUERY FOR SRFFUNCTIONS */
  const getSRFfunctions = async () => GraphqlService.getSRFfunctions({
    projectId: project?.code,
  });

  const dialogRef = useRef(null);

  const {
    data: remoteFunctions,
    error: errorFetchingRemoteFunctions,
    isLoading: isLoadingRemoteFunctions,
  } = useQuery(['SRFfunctions', project?.code], getSRFfunctions, {
    enabled: !!project?.code,
    cacheTime: 0,
    select: (data) => {
      // Ensure project code is available
      if (!project?.code) return data.result;

      return data.result
        .filter((item) => item.functionUniqueName !== project.code?.toString()) // Filter out items that are exactly equal to the project code
        .map((item) => {
          // Remove the project code prefix followed by the first underscore
          const prefix = `${project.code}_`;

          return {
            ...item,
            functionUniqueName: item.functionUniqueName.startsWith(prefix)
              ? item.functionUniqueName.slice(prefix.length) // Remove the project code prefix
              : item.functionUniqueName, // Leave unchanged if no prefix
          };
        });
    },
  });

  /** QUERY FOR SRFBACKENDS */
  const getSRFBackends = async () => GraphqlService.getSRFBackends({
    projectId: project?.code,
  });

  const {
    data: dataSRFBackends,
    error: errorFetchingBackends,
    isLoading: isLoadingBackends,
  } = useQuery(['SRFBackends', project?.code], getSRFBackends, {
    enabled: !!project?.code,
    cacheTime: 0,
  });

  /** QUERY FOR SRFREQUESTS */
  // Add a refresh counter to force the query to re-run
  const [refreshCounter, setRefreshCounter] = useState(0);

  const getSRFRequest = async () => {
    // Ensure we have necessary data before making the request
    if (!project?.code || !mutationResult?.payload?.reqId) {
      return null;
    }
    console.log(`Fetching SRF request: project=${project?.code}, reqId=${mutationResult.payload.reqId}`);

    try {
      const response = await GraphqlService.getSRFRequest({
        projectId: project?.code,
        reqId: mutationResult.payload.reqId,
      });
      return response;
    } catch (error) {
      console.error('Error fetching SRF request:', error);
      throw error;
    }
  };

  const {
    data: SRFRequestData,
    refetch: refetchSRFRequest,
  } = useQuery(
    ['SRFrequest', mutationResult?.payload?.reqId, refreshCounter],
    getSRFRequest,
    {
      enabled: Boolean(mutationResult?.payload?.reqId),
      cacheTime: 0,
      staleTime: 0,
      onError: (error) => {
        console.error('Error in SRF request query:', error);
      },
    },
  );

  useEffect(() => {
    if (SRFRequestData) {
      console.log('SRF request data:', SRFRequestData);
      // Make sure we update the entire result object, not just the payload
      setMutationResult((prev) => ({
        ...prev,
        payload: SRFRequestData?.payload,
        encodedToken: SRFRequestData?.encodedToken,
        header: SRFRequestData?.header,
      }));
    }
  }, [SRFRequestData]);

  const handleSRFRequest = () => {
    console.log('Refreshing SRF request data...');
    // Increment the counter to force a refetch
    setRefreshCounter((prev) => prev + 1);
    // Also call refetch directly
    refetchSRFRequest();
  };

  /** QUERY FOR SRFFUNCTIONS */
  const getSRFToken = async () => GraphqlService.getSRFToken({
    privateKey,
    payload: JSON.stringify(payloadPreview),
    algorithm: privateKeyAlgorithm,
  });

  const {
    data: SRFTokenResult,
    error: SRFTokenError,
  } = useQuery(['SRFToken', privateKey, payloadPreview], getSRFToken, {
    enabled: !!project?.code && !!payloadPreview && !!privateKey,
    cacheTime: 0,
  });

  const executeSRF = useMutation((variables) => GraphqlService.executeSRF(
    variables.projectId,
    variables.deviceSerial,
    variables.functionName,
    variables.securityMode,
    variables.jwtToken,
  ));

  /** FUNCTIONS */
  const openSnackBar = useSnackbar();

  const handleStepClick = (step) => {
    setActiveStep(step);
  };

  const onCancel = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const deleteFormData = () => {
    setActiveStep(0);
    setDeviceSerial(null);
    setSecurityMode(null);
    setSRFfunction('');
    setExpirationTime(moment().add(24, 'hours').format('HH:mm'));
    setExpirationDate(moment().add(24, 'hours'));
    setIssuer();
    setIssuedAtDate(null);
    setIssuedAtTime(null);
    setPrivateKey(null);
    setNumberOfRetries('');
    setOptionalParams('');
    setPayloadPreview('');
    setOptionalParamsError(null);
    setOpenDialog(false);
    setMutationResult(null);
  };

  const handleCancel = () => {
    onCancel();
  };

  const handleResponseTabChange = (event, newValue) => {
    setResponseTabValue(newValue);
  };

  const handleComplete = async () => {
    try {
      const resultExecuteSRF = await executeSRF.mutateAsync({
        projectId: project?.code,
        deviceSerial: deviceSerial.trim(),
        functionName: SRFfunction.functionUniqueName,
        securityMode,
        jwtToken: SRFTokenResult,
      });
      setMutationResult(resultExecuteSRF?.result); // Store result in state
      openSnackBar('Message sent successfully', 'success');
    } catch (error) {
      openSnackBar('Error creating message', 'error');
    }
  };

  const handleSRFfunctionChange = (event) => {
    setSRFfunction(event.target.value);
    setSecurityMode(event.target.value.minSecure.toString());
  };

  const handleSRFIssuerChange = (event) => {
    const selectedBackend = dataSRFBackends?.find((backend) => backend.sourceId === event.target.value);
    if (!selectedBackend) return;
    setIssuer(selectedBackend.sourceId);
    setPrivateKeyAlgorithm(selectedBackend.algorithm);
  };
  const step1Complete = stringHasValue(deviceSerial) && stringHasValue(SRFfunction);

  const step2Complete = stringHasValue(issuer) && stringHasValue(privateKey) && stringHasValue(payloadPreview);

  const handlePrevious = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      openSnackBar('Value copied successfully to the clipboad!', 'success');
    }).catch((err) => {
      openSnackBar('Failed to copy text to clipboard', err);
    });
  };

  // Function to determine the class based on the status
  const getStatusTabClass = (status) => {
    switch (String(status).toLocaleUpperCase()) {
      case 'SCHEDULED':
        return classes.scheduledTab;
      case 'OK':
        return classes.okTab;
      case 'EXECUTING':
        return classes.executingTab;
      default:
        return classes.regularTab;
    }
  };

  const getSecurityModeText = useMemo(() => {
    switch (securityMode) {
      case '2':
        return 'Strong';
      case '1':
        return 'Light';
      default:
        return 'None';
    }
  }, [securityMode]);

  const generatePayload = useMemo(() => () => {
    if (!issuer || !deviceSerial || !SRFfunction || !securityMode || !project?.code) {
      setPayloadPreview('');
      return;
    }
    setOptionalParamsError(null);
    const basePayload = {
      iss: issuer ? issuer.trim() : '',
      aud: `${project?.code}_${deviceSerial.trim()}`,
      sub: SRFfunction.functionUniqueName,
    };

    if (expirationDate && expirationTime) {
      const expDateTime = moment(expirationDate).set({
        hour: moment(expirationTime, 'HH:mm').get('hour'),
        minute: moment(expirationTime, 'HH:mm').get('minute'),
      });
      basePayload.exp = expDateTime.unix();
    }

    let parsedParams = {};
    if (optionalParams.trim() !== '') {
      try {
        parsedParams = { ...JSON.parse(`{${optionalParams.trim()}}`) };
      } catch (error) {
        setOptionalParamsError('Invalid JSON format for optional parameters');
      }
    }

    if (numberOfRetries) {
      parsedParams.retry = numberOfRetries;
    }

    const finalPayload = { ...basePayload, ...parsedParams };

    if (issuedAtDate && issuedAtTime) {
      finalPayload.iat = moment(issuedAtDate).set({
        hour: moment(issuedAtTime, 'HH:mm').get('hour'),
        minute: moment(issuedAtTime, 'HH:mm').get('minute'),
      }).unix();
    }

    setPayloadPreview(JSON.stringify(finalPayload, null, 2));
  }, [
    issuer,
    deviceSerial,
    SRFfunction,
    securityMode,
    numberOfRetries,
    optionalParams,
    issuedAtDate,
    issuedAtTime,
    project,
    expirationTime,
    expirationDate,
  ]);

  useEffect(() => {
    generatePayload();
  }, [issuer,
    deviceSerial,
    SRFfunction,
    securityMode,
    numberOfRetries,
    optionalParams,
    issuedAtDate,
    issuedAtTime,
    project,
    expirationTime,
    generatePayload,
  ]);

  const handleIssuedAtDateChange = (date) => {
    setIssuedAtDate(moment(date));
    generatePayload();
  };

  const handleChangeSelectedIssuedAtTime = (event) => {
    setIssuedAtTime(event.target.value);
    generatePayload();
  };

  /** USE EFFECTS */
  useEffect(() => {
    if (project) {
      deleteFormData();
    }
  }, [project]);

  const handleExpirationDateChange = (date) => {
    setExpirationDate(moment(date));
    generatePayload();
  };

  const handleChangeSelectedExpirationTime = (event) => {
    setExpirationTime(event.target.value);

    generatePayload();
  };

  const handleActiveTab = (event, newValue) => {
    setActiveTab(newValue);
  };

  /** STEPS DEFINITION */
  const steps = [
    {
      label: 'Target and Function',
      description: 'Define a Device and SRF.',
      content: (
        <Grid container className={classes.mainContainer} spacing={2}>
          <Grid item md={6}>
            <InputLabel htmlFor="deviceSerial">
              Device Serial
              {' '}
              <Typography component="span" color="error">*</Typography>
            </InputLabel>

            <TextField
              id="device-serial"
              value={deviceSerial || ''}
              onChange={(e) => setDeviceSerial(e.target.value)}
              variant="outlined"
              style={{ width: '100%', marginTop: '8px', backgroundColor: 'white' }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl variant="filled" className={classes.selectSRFfunctions}>
              <InputLabel id="select-function">Select Function</InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={SRFfunction}
                onChange={handleSRFfunctionChange}
              >
                {
                  remoteFunctions
                    ?.filter((func, index, self) => index === self.findIndex((f) => f.functionUniqueName === func.functionUniqueName))
                    .map((func) => (
                      <MenuItem key={func.functionUniqueName} value={func}>
                        {func.functionUniqueName}
                      </MenuItem>
                    ))
                }

              </Select>
            </FormControl>
          </Grid>
          {SRFfunction && (
            <Grid item xs={12}>
              <Box
                bgcolor="#F5E6CA" // Yellow cake background
                borderRadius={4}
                p={2}
                position="relative"
              >
                <InfoIcon
                  color="primary"
                  style={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                  }}
                />

                <InputLabel htmlFor="securityMode">
                  {' '}
                  Security mode is automatically set to
                  {' '}
                  {getSecurityModeText}
                </InputLabel>

              </Box>
            </Grid>
          )}
        </Grid>
      ),
    },

    {
      label: 'Message Payload',
      description: 'Set the Payload for your message',
      content: (
        <Grid container className={classes.mainContainer} spacing={2}>
          <Grid item md={6}>
            <InputLabel htmlFor="expirationTime">
              Expiration Time
              {' '}
              <Typography component="span" color="error">*</Typography>
            </InputLabel>
            <Grid container className={classes.expirationPickers}>

              <Box display="flex">
                <Box paddingRight={2}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      autoOk
                      disableToolbar
                      variant="outlined"
                      margin="normal"
                      format="DD-MM-YYYY"
                      value={expirationDate}
                      onChange={handleExpirationDateChange}
                      inputVariant="outlined"
                    />
                  </MuiPickersUtilsProvider>
                </Box>

                <Box className={classes.expirationTimePicker}>
                  <TextField
                    id="inputStartTimeFrom"
                    value={expirationTime}
                    variant="outlined"
                    onChange={handleChangeSelectedExpirationTime}
                    orientation="landscape"
                    inputVariant="outlined"
                    type="time"
                    color="secondary"
                  />
                </Box>
              </Box>

            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControl variant="filled" className={classes.selectIssuer}>
              <InputLabel id="issuer-input-label">
                Issuer
                {' '}

                <Typography component="span" color="error">*</Typography>
              </InputLabel>
              <Select
                labelId="demo-simple-select-filled-label"
                id="demo-simple-select-filled"
                value={dataSRFBackends?.find((backend) => backend.sourceId === issuer)?.sourceId || ''}
                onChange={handleSRFIssuerChange}
              >
                {
                  dataSRFBackends?.map((backend) => (
                    <MenuItem key={backend.sourceId} value={backend.sourceId}>{backend.sourceId}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel htmlFor="privateKeyAlgorithm">
              Private Key Algorithm
            </InputLabel>
            <TextField
              id="alorithmTextField"
              value={privateKeyAlgorithm || ''}
              disabled
              variant="outlined"
              style={{ width: '100%', marginTop: '8px' }}
            />
          </Grid>

          <Grid item md={12}>
            <InputLabel htmlFor="privateKey">
              Private Key
              {' '}
              <Typography component="span" color="error">*</Typography>
            </InputLabel>
            <textarea
              id="privateKey"
              value={privateKey || ''}
              onChange={(e) => setPrivateKey(e.target.value)}
              rows="3"
              cols="50"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />

          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel htmlFor="issuedAt">
              Issued At
            </InputLabel>
            <Box display="flex">
              <Box paddingRight={2} className={classes.expirationPickers}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disableToolbar
                    variant="outlined"
                    margin="normal"
                    format="DD-MM-YYYY"
                    value={issuedAtDate}
                    onChange={handleIssuedAtDateChange}
                    inputVariant="outlined"
                  />
                </MuiPickersUtilsProvider>
              </Box>
              <Box className={classes.issuedTimePicker}>
                <TextField
                  id="inputStartTimeFrom"
                  value={issuedAtTime || ''}
                  variant="outlined"
                  onChange={handleChangeSelectedIssuedAtTime}
                  orientation="landscape"
                  inputVariant="outlined"
                  type="time"
                  color="secondary"
                />
              </Box>
            </Box>
          </Grid>

          <Grid item md={6} xs={12}>
            <InputLabel htmlFor="numberOfRetries">
              Number of retries
            </InputLabel>
            <TextField
              id="numberOfRetries"
              type="number"
              value={numberOfRetries || ''}
              onChange={(e) => setNumberOfRetries(e.target.value)}
              variant="outlined"
              style={{ width: '100%', marginTop: '8px', backgroundColor: 'white' }}
            />
          </Grid>

          <Grid item md={12}>
            <InputLabel htmlFor="privateKey">
              Optional parameters (Set values as key-value pairs)
            </InputLabel>
            <textarea
              id="optionalParams"
              value={optionalParams || ''}
              onChange={(e) => setOptionalParams(e.target.value)}
              rows="3"
              cols="50"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
            {optionalParamsError && ( // Conditionally render error message
              <Typography variant="caption" color="error" component="div">
                {optionalParamsError}
              </Typography>
            )}
          </Grid>

          <Grid item md={12}>
            <InputLabel htmlFor="payloadPreview">
              Payload Preview
            </InputLabel>
            <textarea
              id="payloadPreview"
              value={payloadPreview || ''}
              rows="9"
              cols="50"
              disabled
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>
        </Grid>
      ),
    },

    {
      label: 'Confirm Message',
      description: 'Review and send your message',
      content: (
        <Grid container display="flex" spacing={4}>
          <Grid item xs={12}>
            <InputLabel htmlFor="deviceSerial"> Device Serial</InputLabel>

            <TextField
              id="device-serial-confirm"
              value={deviceSerial || ''}
              disabled
              variant="outlined"
              style={{ width: '100%', marginTop: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="SRFfunction"> Function</InputLabel>
            <TextField
              id="srf-function-confirm"
              value={SRFfunction?.functionUniqueName || ''}
              disabled
              variant="outlined"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>
          <Grid item xs={6}>
            <InputLabel htmlFor="securityMode"> Security Mode</InputLabel>
            <TextField
              id="securityMode"
              value={getSecurityModeText || ''}
              disabled
              variant="outlined"
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>
          <Grid item md={12}>
            <InputLabel htmlFor="payloadPreview">
              Payload Preview
            </InputLabel>
            <textarea
              id="payloadPreview"
              value={payloadPreview || ''}
              rows="9"
              cols="50"
              disabled
              style={{ width: '100%', padding: '8px', marginTop: '8px' }}
            />
          </Grid>
          <Grid item md={12}>
            <InputLabel htmlFor="jwtToken">JWT Token</InputLabel>
            <Box display="flex" alignItems="center" mt={1}>
              <TextField
                id="jwtToken"
                value={SRFTokenResult || ''}
                disabled
                variant="outlined"
                style={{ flexGrow: 1 }}
              />
              {SRFTokenResult && (
                <IconButton onClick={() => copyToClipboard(SRFTokenResult)}>
                  <FileCopyIcon />
                </IconButton>
              )}
            </Box>
            {SRFTokenError && (
              <Typography variant="caption" color="error" component="div">
                {SRFTokenError}
              </Typography>
            )}
          </Grid>

          {(mutationResult?.payload)
            && (
              <Box
                bgcolor="#F5E6CA" // Yellow cake background
                borderRadius={4}
                p={2}
                width="100%"
                position="relative"
              >
                <Typography variant="h3">
                  Response
                </Typography>
                <div>

                  <Tabs value={responseTabValue} onChange={handleResponseTabChange} aria-label="dynamic status tabs">
                    <Tab
                      label={`${mutationResult.payload?.status || 'Unknown'} Response`}
                      className={getStatusTabClass(mutationResult.payload?.status)}
                    />
                    <Tab label="Device Response" disabled={!mutationResult.payload?.results} />
                  </Tabs>

                  <Box p={3} hidden={responseTabValue !== 0}>
                    <Grid container display="flex" spacing={2}>
                      <Grid item md={4}>
                        <InputLabel htmlFor="responseEncodedToken">
                          Encoded Token
                        </InputLabel>
                        <textarea
                          id="responseEncodedToken"
                          value={mutationResult.encodedToken}
                          rows="15"
                          cols="50"
                          disabled
                          style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InputLabel htmlFor="responseHeader">
                          Decoded Header
                        </InputLabel>
                        <textarea
                          id="responseHeader"
                          value={mutationResult.header}
                          rows="15"
                          cols="50"
                          disabled
                          style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                        />
                      </Grid>
                      <Grid item md={4}>
                        <InputLabel htmlFor="responseHeader">
                          Decoded Payload
                        </InputLabel>
                        <textarea
                          id="responsePayload"
                          value={JSON.stringify(mutationResult.payload, null, 2)}
                          rows="15"
                          cols="50"
                          disabled
                          style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  <Box p={3} hidden={responseTabValue !== 1}>
                    <Grid container display="flex" spacing={2}>
                      <Grid item md={4}>
                        <textarea
                          id="responsePayloadResults"
                          value={JSON.stringify(mutationResult.payload?.results, null, 2)}
                          rows="15"
                          cols="50"
                          disabled
                          style={{ width: '100%', padding: '8px', marginTop: '8px' }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </div>

                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSRFRequest}
                  startIcon={<CachedIcon />}
                  disabled={activeStep === 0}
                  className={classes.button}
                >
                  Refresh
                </Button>

              </Box>
            )}

        </Grid>
      ),
    },
  ];

  /** RETURN JSX */
  if (isLoadingRemoteFunctions || !project?.code) {
    return (
      <>
        <ProgressDialog open header="Retrieving Secure Remote functions, please wait" />
        <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
      </>
    );
  }

  if (errorFetchingRemoteFunctions) {
    return <MessageCard message={`Error: ${errorFetchingRemoteFunctions?.message}`} />;
  }

  return (
    <Box className={classes.wizardContainer} id="cloud-to-device">
      <PageBanner title="CLOUD TO DEVICE" />

      <div>
        {/* Tabs para definir las pestañas */}
        <Tabs value={activeTab} onChange={handleActiveTab} aria-label="simple tabs example">
          <Tab label="Trigger SRF" {...a11yProps(0)} />
          <Tab label="Request Manager" {...a11yProps(0)} id="requestManagerTab" />
        </Tabs>

        {/* Contenido de cada pestaña */}
        <TabPanel value={activeTab} index={0}>
          <Stepper activeStep={activeStep}>
            {steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  className={classes.stepLabel}
                  onClick={() => handleStepClick(index)}
                >
                  {step.label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          <motion.div
            key={activeStep}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -0.5 }}
            transition={{ duration: 0.5 }}
          >
            <div className={classes.stepWizardContent}>
              <Typography className={classes.instructions} component="div">
                {steps[activeStep].description}
              </Typography>
              {steps[activeStep].content}
            </div>
          </motion.div>
          <Box className={classes.footerButtons}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCancel}
              className={classes.button}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={handlePrevious}
              startIcon={<ArrowBackIcon />}
              disabled={activeStep === 0 || mutationResult}
              className={classes.button}
            >
              Previous
            </Button>
            {activeStep === steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleComplete}
                disabled={deviceSerial === ''}
                className={classes.button}
              >
                {mutationResult ? 'Resend' : 'Send'}
              </Button>
            )}
            {activeStep !== steps.length - 1 && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowForwardIcon />}
                disabled={activeStep === 1 ? !step2Complete : !step1Complete}
                onClick={handleNext}
                className={classes.button}
              >
                Next
              </Button>
            )}
          </Box>

          <Dialog
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            ref={dialogRef}
          >
            <DialogTitle
              id="alert-dialog-title"
            >
              Are you sure you want to cancel?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">

                All the data in the form will be deleted.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                CANCEL
              </Button>
              <Button
                onClick={deleteFormData}
                color="primary"
                autoFocus
              >
                YES
              </Button>
            </DialogActions>
          </Dialog>
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <RequestManager />
        </TabPanel>
      </div>

    </Box>
  );
}
