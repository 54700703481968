import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const ProjectContext = createContext();

export const useProject = () => useContext(ProjectContext);

export const ProjectProvider = ({ children }) => {
  const [project, setProject] = useState(() => {
    const storedProject = localStorage.getItem('project');
    const result = storedProject
      ? JSON.parse(storedProject) : {
        code: '',
        name: '',
        devicePermissions: [],
        role: '',
      };
    return result;
  });

  const value = { project, setProject };

  return <ProjectContext.Provider value={value}>{children}</ProjectContext.Provider>;
};

ProjectProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
