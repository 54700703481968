/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, LinearProgress,
  Table, TableBody, TableContainer, Paper, TableHead, TableRow,
  TableFooter, TablePagination,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import moment from 'moment';
import { StyledTableCell, StyledTableRow } from '../../../components/utils/StyledTable';
import TablePaginationActions from '../../../components/utils/TablePaginationActions';

const ConnectivityStatusHistory = ({
  data,
  isLoading,
  page,
  rowsPerPage,
  handleChangePage,
  connectivityStatusHistoryToken,
}) => (
  <TableContainer component={Paper}>
    <Table aria-label="custom pagination table">
      <TableHead>
        <TableRow>
          <StyledTableCell>Type</StyledTableCell>
          <StyledTableCell>Reason</StyledTableCell>
          <StyledTableCell>Updated At</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((status) => (
          <StyledTableRow key={`${status.updatedAt}-${status.type}`}>
            <StyledTableCell>
              <Box display="flex" alignItems="center">
                {status.type === 'connected' ? (
                  <CheckCircleIcon style={{ color: 'green', marginRight: 8 }} />
                ) : (
                  <CancelIcon style={{ color: 'red', marginRight: 8 }} />
                )}
                {status.type}
              </Box>
            </StyledTableCell>
            <StyledTableCell>{status.reason || 'NA'}</StyledTableCell>
            <StyledTableCell>{moment(status.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow key="paginationFooter">
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            colSpan={10}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onChangePage={handleChangePage}
            ActionsComponent={(props) => (
              <TablePaginationActions
                {...props}
                onChangePage={handleChangePage}
                count={connectivityStatusHistoryToken ? -1 : data.length}
                page={page}
                rowsPerPage={rowsPerPage}
              />
            )}
            labelDisplayedRows={({ from, to, count }) => {
              if (count !== -1) {
                return `${from}-${to} of ${count}`;
              }

              return `${from}-${to} of more than ${to}`;
            }}
            count={connectivityStatusHistoryToken ? -1 : data.length}
          />
        </TableRow>
      </TableFooter>
    </Table>
    {isLoading && <LinearProgress />}
  </TableContainer>
);

ConnectivityStatusHistory.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      reason: PropTypes.string.isRequired,
      updatedAt: PropTypes.string.isRequired,
    }),
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  connectivityStatusHistoryToken: PropTypes.string,
};

ConnectivityStatusHistory.defaultProps = {
  connectivityStatusHistoryToken: '',
};

export default ConnectivityStatusHistory;
