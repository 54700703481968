import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Button,
  TextField,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SubjectIcon from '@material-ui/icons/Subject';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import * as XLSX from 'xlsx';
import moment from 'moment';
import MessageCard from '../../components/utils/MessageCard';
import GraphqlService from '../../service/graphqlService';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { StyledTableCell, StyledTableRow, StyledTableHeaderCell } from '../../components/utils/StyledTable';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
    padding: 16,
  },
  button: {
    float: 'right',
    margin: '0 0 16px 24px',
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  dateContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 16,
  },
  dateField: {
    marginRight: 16,
    width: 200,
  },
  searchButton: {
    height: 56,
    borderRadius: 12,
  },
  titleCount: {
    fontWeight: 'bold',
    marginLeft: 8,
  },
}));

export default function LastUsersAction() {
  const classes = useStyles();
  const [lastActionBeforeDate, setLastActionBeforeDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'));

  const {
    data: usersData,
    error: errorFetching,
    isLoading,
  } = useQuery(['lastUsersAction'],
    async () => GraphqlService.getLastUsersAction(), {
      cacheTime: 300000, // Cache for 5 minutes
      staleTime: 300000, // Data is considered stale after 5 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    });

  const filterUsersData = (data) => {
    if (!data) return [];
    return data.filter((user) => moment(user.lastAction).isBefore(lastActionBeforeDate));
  };

  const downloadCsv = () => {
    if (!usersData || !usersData.length) return;

    let csvContent = 'User, Email,Last Action Date\n';
    usersData.forEach((user) => {
      const row = `${user.user},${user.cognitoEmail},${user.lastAction}"`;
      csvContent += `${row}\n`;
    });

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'LastUsersAction.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadXls = () => {
    if (!usersData || !usersData.length) return;

    const worksheet = XLSX.utils.json_to_sheet(usersData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    const xlsBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    const blob = new Blob([xlsBuffer], { type: 'application/vnd.ms-excel' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'LastUsersAction.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyUsersInClipboard = () => {
    if (!usersData) return;
    const emails = usersData.map((user) => user.cognitoEmail).join(';');
    navigator.clipboard.writeText(emails);
  };

  function renderContent() {
    if (errorFetching) {
      return <MessageCard message={`Error: ${errorFetching.message}`} />;
    }

    if (isLoading) {
      return (
        <>
          <ProgressDialog open header="Retrieving last users action, please wait" />
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>
      );
    }

    const filteredData = filterUsersData(usersData);

    if (filteredData.length > 0) {
      return (
        <>
          <Grid container direction="column" spacing={2}>
            <div className={classes.tableIcons}>
              <Button
                id="btnCSV"
                type="form"
                startIcon={<GetAppIcon />}
                className={classes.tableButton}
                color="secondary"
                variant="contained"
                onClick={downloadCsv}
              >
                CSV
              </Button>
              <Button
                id="btnXLS"
                type="form"
                startIcon={<SubjectIcon />}
                className={classes.tableButton}
                color="secondary"
                variant="contained"
                onClick={downloadXls}
              >
                XLS
              </Button>
              <Button
                id="btnCopyEmails"
                type="form"
                startIcon={<AssignmentTurnedInIcon />}
                className={classes.tableButton}
                color="secondary"
                variant="contained"
                onClick={copyUsersInClipboard}
              >
                CLIPBOARD EMAILS
              </Button>
            </div>

            <TableContainer component={Paper} className={classes.mainTable}>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableHeaderCell>User</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Email</StyledTableHeaderCell>
                    <StyledTableHeaderCell>Last Action Date</StyledTableHeaderCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((user) => (
                    <StyledTableRow key={user.user}>
                      <StyledTableCell>{user.user}</StyledTableCell>
                      <StyledTableCell>{user.cognitoEmail}</StyledTableCell>
                      <StyledTableCell>{user.lastAction}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </>
      );
    }

    return <MessageCard message="No users found for the selected date range" />;
  }

  return (
    <Box>

      <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">
        <div className={classes.dateContainer}>
          <TextField
            id="start-date"
            label="Last Action Before"
            type="date"
            value={lastActionBeforeDate}
            onChange={(e) => setLastActionBeforeDate(e.target.value)}
            className={classes.dateField}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>
        <div>
          {renderContent()}
        </div>
      </Grid>
    </Box>
  );
}
