/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, Button, makeStyles,
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
} from '@material-ui/core';
import moment from 'moment';
import {
  React, useEffect, useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useProject } from '../../config/ProjectContext';
import GraphqlService from '../../service/graphqlService';
import PageBanner from '../../components/utils/PageBanner';
import SimpleAlert from '../../components/utils/SimpleAlert';
import ProgressDialog from '../../components/utils/ProgressDialog';
import BrandConsumptionsTable from './MachineTelemetry/BrandConsumptionsTable';
import { validate, calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../utils/dateUtils';
import JSONBuilderFilters, { valueTypes } from '../../components/utils/JSONBuilderFilters';
import FineGrainedPermissionsInfo from '../Admin/PlatformHealth/FineGrainedPermissions/FineGrainedPermissionsInfo';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  topContent: {
    padding: 24,
  },
  contentInfo: {
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.default,
  },
  topField: {
    minHeight: 76,
  },
  bottomLeftFields: {
    paddingLeft: 24,
  },
  bottomRightFields: {
    paddingLeft: 24,
  },
  errorAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
  bottomRightButton: {
    paddingTop: 32,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 12,
    width: 138,
  },
  searchIcon: {
    marginRight: '16px',
  },
  picker: {
    '& .MuiIconButton-root': {
      color: 'black',
    },
    width: '100%',
  },
  bottomContent: {
    paddingTop: 16,
    paddingBottom: 12,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
  noResultsMessage: {
    padding: 24,
    textAlign: 'center',
  },
  retryButtons: {
    marginTop: 16,
    display: 'flex',
    justifyContent: 'center',
    gap: 16,
  },
}));

export default function BrandConsumptions() {
  const MAX_DAYS_BETWEEN_DATES = 30;
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [paginationTokens, setPaginationTokens] = useState(['']);
  const [rowsPerPageConsumption] = useState(10);
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');
  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);
  const [filterError, setFilterError] = useState('');

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-29, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-29, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);

  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [reducedResponse, setReducedResponse] = useState(null);

  // timestamp type toggle
  const [useProcessedTimestamp, setUseProcessedTimestamp] = useState(false);
  const [noResultsDialogOpen, setNoResultsDialogOpen] = useState(false);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  const jsonColumns = [
    {
      id: 'id', label: 'ID', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'device_serial', label: 'Device Serial', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'module_serial', label: 'Module Serial', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'country_code', label: 'Country Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'recipe_code', label: 'Recipe Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'product_code', label: 'Product Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'customer_code', label: 'Customer Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'is_valid_time', label: 'Is Valid Time', valueType: valueTypes.BOOLEAN, values: ['true', 'false'],
    },
    {
      id: 'extra', label: 'Extra', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'created_on_local', label: 'Created On Local', valueType: valueTypes.DATE, values: [],
    },
    {
      id: 'created_on_utc', label: 'Created On UTC', valueType: valueTypes.DATE, values: [],
    },
    {
      id: 'received_on_utc', label: 'Received On UTC', valueType: valueTypes.DATE, values: [],
    },
    {
      id: 'processed_on_utc', label: 'Processed On UTC', valueType: valueTypes.DATE, values: [],
    },
  ];

  async function searchConsumption(newPage) {
    const startDate = calculateStartDateTimeIso(selectedStartDate, selectedStartTime);
    const endDate = calculateEndDateTimeIso(selectedEndDate, selectedEndTime);
    const consumptionResponse = await GraphqlService.getBrandConsumptions(
      project.code, selectedFilters, startDate, endDate, rowsPerPageConsumption, newPage * rowsPerPageConsumption || 0, useProcessedTimestamp,
    );

    // map the result for each call in a unique response
    // eslint-disable-next-line no-plusplus
    if (consumptionResponse) {
      if (totalCount < 1 || !newPage) {
        setTotalCount(consumptionResponse.pagination.totalRows);
      }
      return consumptionResponse.brandConsumptions;
    }
    return [];
  }

  async function search(newPage) {
    try {
      setDateRangePickerVisibility(!dateRangePickerVisibility);
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchConsumption(newPage);
      setPaginationTokens(['']);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      if (creationResponse ? creationResponse?.length < 1 && !useProcessedTimestamp : false) {
        setNoResultsDialogOpen(true);
      }

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);
      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setPaginationTokens(['']);
  }, [selectedFilters, selectedStartDate, selectedEndDate]);

  const onSearch = (event, filters) => {
    event.preventDefault();
    if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
      search();
    }
  };

  const handleRetryWithProcessedTimestamp = () => {
    // Automatically adjust date range to last 3 days
    const newEndDate = moment();
    const newStartDate = moment(newEndDate).subtract(3, 'days');

    // Update date and time state variables
    setSelectedEndDate(newEndDate);
    setSelectedEndTime(newEndDate.format('23:59'));
    setSelectedStartDate(newStartDate);
    setSelectedStartTime(newStartDate.format('00:00'));

    // Enable processed timestamp toggle
    setUseProcessedTimestamp(true);

    setNoResultsDialogOpen(false);

    // Perform search with processed timestamp
    setTimeout(() => {
      search(0);
    }, 100);
  };

  const handleKeepResults = () => {
    setNoResultsDialogOpen(false);
  };

  return (
    <Box spacing={0}>
      <ProgressDialog open={progressDialogOpen} setOpen={setProgressDialogOpen} header="Retrieving statistics, please wait" />
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <PageBanner title="DEVICE CONSUMPTIONS" />

      <FineGrainedPermissionsInfo
        open={false}
        onClose={() => { }}
        style={{ margin: 24 }}
      />

      <div className={classes.content}>
        <JSONBuilderFilters
          id="Consumptions"
          setSelectedFilters={setSelectedFilters}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedStartTime={selectedStartTime}
          setSelectedStartTime={setSelectedStartTime}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedEndTime={selectedEndTime}
          setSelectedEndTime={setSelectedEndTime}
          filtersVisible
          deviceSerialVisible={false}
          jsonColumns={jsonColumns}
          setDateRangePickerVisibility={dateRangePickerVisibility}
          setFilterError={setFilterError}
          filterError={filterError}
          onSearch={onSearch}
          showNoEndateCheckbox={false}
          useProcessedTimestamp={useProcessedTimestamp}
          setUseProcessedTimestamp={setUseProcessedTimestamp}
          showTimestampToggle
        />
      </div>

      <Dialog
        open={noResultsDialogOpen}
        onClose={handleKeepResults}
        aria-labelledby="no-results-dialog-title"
        aria-describedby="no-results-dialog-description"
      >
        <DialogTitle id="no-results-dialog-title">No Results Found</DialogTitle>
        <DialogContent>
          <DialogContentText id="no-results-dialog-description">
            Your search was conducted using the machine&apos;s timestamp (created_on).
            Please note that there may be instances where this timestamp is incorrectly set,
            which could affect the search results.
          </DialogContentText>
          <DialogContentText style={{ marginTop: 8 }}>
            Would you like to perform the search using the processed timestamp (processed_on) instead?
            This will automatically adjust your search to the last 3 days.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            onClick={handleRetryWithProcessedTimestamp}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            color="default"
            onClick={handleKeepResults}
          >
            No
          </Button>
        </DialogActions>
      </Dialog>

      <Grid>
        <BrandConsumptionsTable
          search={(newPage) => search(newPage || 0)}
          dataList={reducedResponse}
          totalCount={totalCount}
          filters={selectedFilters}
          loading={loading}
          dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
          dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
          page={reducedPage}
          setPage={setReducedPage}
          rowsPerPage={rowsPerPageConsumption}
        />
      </Grid>
    </Box>
  );
}
