import React from 'react';
import {
  InputLabel, FormControl, Select, MenuItem, makeStyles, Grid,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import PropTypes from 'prop-types';

const PaginationComponent = ({
  pageSize, handleSelectChange, pagination, handlePageChange,
}) => {
  const useStyles = makeStyles((theme) => ({
    separate: {
      flexWrap: 'noWrap',
    },
    pagination: {
      background: theme.palette.background.paper,
      borderRadius: 12,
      boxShadow: theme.customShadows.regularShadow,
      color: theme.palette.primary.text,
      padding: 0,
      width: '100%',
    },
    background: {
      backgroundColor: theme.palette.background.default,
      padding: 16,
    },
    button: {
      float: 'right',
      margin: '0 0 16px 24px',
    },
    deployButton: {
      float: 'left',
      margin: '0 0 16px 24px',
    },
    pageSizeTitle: {
      fontSize: 14,
      paddingRight: '24px',
    },
    paginationRoot: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
    },
    selectPaginationSize: {
      marginRight: '8px',
    },
    paginationSizeLabel: {
      paddingRight: '12px',
      fontSize: 16,
    },
    tableIcons: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      paddingBottom: 16,

    },
    tableButton: {
      width: '100%',
      height: 48,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    refreshButton: {
      height: 48,
      justifyContent: 'center',
      marginLeft: 2,
      color: 'white',
    },
  }));

  const classes = useStyles();

  return (

    <Grid container className={classes.tableIcons}>

      <InputLabel className={classes.paginationSizeLabel}>Page size</InputLabel>

      <FormControl variant="outlined">
        <Select
          value={pageSize}
          onChange={handleSelectChange}
          displayEmpty
          className={classes.selectPaginationSize}
        >
          <MenuItem value={5} key="paginationSelector-5">5</MenuItem>
          <MenuItem value={10} key="paginationSelector-10">10</MenuItem>
          <MenuItem value={50} key="paginationSelector-50">50</MenuItem>
          <MenuItem value={100} key="paginationSelector-100">100</MenuItem>
        </Select>
      </FormControl>

      {pageSize !== -1 && pagination && (

        <Pagination
          count={pagination.totalPages}
          page={pagination.pageNumber + 1}
          onChange={handlePageChange}
          color="primary"
          key="pagination"
        />

      )}
    </Grid>

  );
};

PaginationComponent.propTypes = {
  pageSize: PropTypes.number.isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  pagination: PropTypes.shape({

    totalPages: PropTypes.number,
    pageNumber: PropTypes.number,

  }).isRequired,
  handlePageChange: PropTypes.func.isRequired,
};

export default PaginationComponent;
