/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import './App.css';
import React, { useEffect } from 'react';
import { HashRouter } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import { createTheme, ThemeProvider } from '@material-ui/core';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { Auth, Hub } from 'aws-amplify';
import Layout from './components/Layout';
import { loadSharedHooks } from './config/hooksLoader';
import { SnackbarProvider } from './providers/SnackbarContext';
import { target } from './config/awsExports';
import { ProjectProvider } from './config/ProjectContext';
import AnalyticsHandler from './config/AnalyticsHandler';
import { targetName } from './utils/portalEnvs';

const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#295299',
      subMain: '#295299',
      text: '#324149',
      button: '#295299',
      buttonImportant: '#295299 !important',
      banner: 'rgba(239, 100, 34, 0.7)',
      bannerText: '#ffffff',
      bannerButton: '#ffffff',
      labelDescription: '#000000de',
      labelHeader: '#808080',
      bottomBorder: '#CCCCCC',
      footer: '#63513D',
      subFooter: '#E6E6E6',
      modelPicker: '#4971B7',
      deleteButton: '#DD3232',
      ligthButton: '#E2E8F3',
    },
    secondary: {
      main: '#295299',
      subMain: '#E2E8F3',
      text: '#324149',
      contrastText: '#000000',
      button: '#000',
      subText: '#333333',
    },
    error: {
      main: '#DD3232',
      statistics: '#D50000',
    },
    warning: {
      main: '#FAAF19',
      statistics: '#FA6119',
    },
    success: {
      main: '#3D8705',
      statistics: '#3F9C35',
    },
    published: {
      text: '#00A35E',
      background: '#E5F5EE',
    },
    notPublished: {
      text: '#808080',
      background: '#EFEFEF',
    },
    globallyDeployed: {
      text: '#277777',
      background: 'rgba(39,119,119,0.1)',
    },
    cancelled: {
      text: '#DD3232',
      background: 'rgba(221,50,50,0.25)',
    },
    background: {
      paper: '#f5f7fb',
      default: 'white',
      tableHeader: 'rgba(239, 100, 34, 0.7)',
      separator: '#A1A1A1',
      background: 'green',
      dropzone: 'blue',
      black: '#000000',
      typography: 'pink',
      form: '#E2EAF9',
      accentForm: '#cbd2e0',
      footer: '#E2EAF9',
      tabContent: '#F5F6FA',
      header: 'white',
    },
    colors: {
      statistics: {
        color1: '#0066A1',
        color2: '#EF6422',
        color3: '#3F9C35',
        color4: '#FCBF10',
        color5: '#6D8DC5',
        color6: '#C40000',
        color7: '#F177D9',
        color8: '#00A59C',
        color9: '#0066A1',
        color10: '#277777',
        color11: '#FF5757',
        color12: '#9AC4DC',
        color13: '#D8B4E2',
        color14: '#588B8B',
        color15: '#98C1D9',
        color16: '#C1EDCC',
        color17: '#F5DD90',
        color18: '#90708C',
        color19: '#FFDBDA',
        color20: '#A1E5AB',
        color21: '#D2AB99',
        color22: '#90BAAD',
        color23: '#DEC0F1',
        color24: '#D4F2D2',
        color25: '#E6AA68',
      },
    },
  },
  typography: {
    fontFamily: ['Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif'].join(','),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600,
    fontWeightBolder: 700,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.3rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.2rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1.1rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 300,
      fontSize: '1.1rem',
      lineHeight: 1.167,
      letterSpacing: '-0.01562em',
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '16px',
      letterSpacing: '0',
    },
    h6: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '20px',
      lineHeight: '24px',
      letterSpacing: '0',
    },
    h7: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    h8: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '20px',
      letterSpacing: '0',
    },
    h9: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '14px !important',
      lineHeight: '16px',
      letterSpacing: '0',
    },
    h10: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '28px !important',
      lineHeight: '33px',
      letterSpacing: '0',
    },
    h11: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0',
    },
    h12: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '19px',
      letterSpacing: '0',
    },
    releaseTableHeader: {
      lineHeight: 0,
      fontSize: 12,
    },
    releaseTableText: {
      fontSize: 14,
    },
  },
  customShadows: {
    wholeShadow: '0px 0px 0px 0px rgba(0,0,0,0.24)',
    rightShadow: '0 0 6px 0 rgba(0,0,0,0.24)',
    wholeShadowAllSides: '0px 0px 4px 0px rgba(0,0,0,0.24)',
    noShadow: '0px 0px 0px 0px rgba(0,0,0,0.24)',
    regularShadow: '0 1px 2px 0 rgba(0,0,0,0.2), 0 1px 5px 0 rgba(0,0,0,0.13)',
  },
  overrides: {
    MuiListItem: {
      root: {
        '&$selected, $selected:hover': {
          color: '#295299',
          backgroundColor: 'white',
          '& g': {
            // fill: '#8F7247',
            fill: '#295299',
          },
          '& .MuiTypography-displayBlock': {
            fontWeight: 500,
          },
        },
      },
    },
    MuiListItemIcon: {
      root: {
        color: '',
      },
    },
    MuiFilledInput: {
      input: {
        '&::placeholder': {
          color: '#666666',
          opacity: 1,
        },
      },
    },
    MuiInputBase: {
      input: {
        '&:autofill': {
          WebkitBoxShadow: '0 0 0 100px white inset',
        },
        '&:-webkit-autofill': {
          WebkitBoxShadow: '0 0 0 100px white inset',
        },
      },
    },
    MuiAccordion: {
      rounded: {
        borderRadius: 12,
        '&:first-child': {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
        },
      },
    },
    MuiAccordionSummary: {
      content: {
        margin: '10px 0',
        '&.Mui-expanded': {
          padding: 0,
          margin: 0,
        },
      },
    },
  },
});

const queryClient = new QueryClient();

const App = () => {
  loadSharedHooks();

  const insertGTM = (gtmId) => {
    const script = document.createElement('script');
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push(
    {'gtm.start': new Date().getTime(),event:'gtm.js'});
    var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
    j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
    f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer', '${gtmId}');`;
    document.head.appendChild(script);

    const noscript = document.createElement('noscript');
    const iframe = document.createElement('iframe');
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${gtmId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';
    iframe.sandbox = 'allow-scripts';
    noscript.appendChild(iframe);
    document.body.insertBefore(noscript, document.body.firstChild);
  };

  const initializeGA4 = (measurementId, userEmail) => {
    // Add Google Analytics tracking
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`;
    document.head.appendChild(script);
  };

  useEffect(() => {
    // Initialize dataLayer at the very beginning
    window.dataLayer = window.dataLayer || [];

    document.title = `Consumer IoT Portal (${targetName[target]})`;

    // Initialize GTM and GA4 with user email for non-DEV environments

    const initAnalytics = async () => {
      const gtmId = target !== 'PROD' ? 'GTM-NM6RPZPX' : 'GTM-T3MTFFG5';
      const ga4Id = target !== 'PROD' ? 'G-XXHNP82K71' : 'G-CW04EG34S8';

      try {
        // Get authenticated user
        const authUser = await Auth.currentAuthenticatedUser();
        const userEmail = authUser?.attributes?.email;

        insertGTM(gtmId);
        initializeGA4(ga4Id, userEmail);
      } catch (error) {
        console.error('Error getting user for analytics:', error);
      }
    };
    initAnalytics();

    // Insert Qualtrics Website Feedback snippet
    const qualtricsScript = document.createElement('script');
    qualtricsScript.type = 'text/javascript';
    qualtricsScript.innerHTML = target === 'PRO' ? `
      (function(){var g=function(e,h,f,g){
        this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
        this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
        this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
        this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
        this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
        try{(new g(100,"r","QSI_S_ZN_1oGdlwsfhmbnD02","https://zn1ogdlwsfhmbnd02-nestleglobalmktg.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_1oGdlwsfhmbnD02")).start()}catch(i){}})();
    ` : `
      (function(){var g=function(e,h,f,g){
        this.get=function(a){for(var a=a+"=",c=document.cookie.split(";"),b=0,e=c.length;b<e;b++){for(var d=c[b];" "==d.charAt(0);)d=d.substring(1,d.length);if(0==d.indexOf(a))return d.substring(a.length,d.length)}return null};
        this.set=function(a,c){var b="",b=new Date;b.setTime(b.getTime()+6048E5);b="; expires="+b.toGMTString();document.cookie=a+"="+c+b+"; path=/; "};
        this.check=function(){var a=this.get(f);if(a)a=a.split(":");else if(100!=e)"v"==h&&(e=Math.random()>=e/100?0:100),a=[h,e,0],this.set(f,a.join(":"));else return!0;var c=a[1];if(100==c)return!0;switch(a[0]){case "v":return!1;case "r":return c=a[2]%Math.floor(100/c),a[2]++,this.set(f,a.join(":")),!c}return!0};
        this.go=function(){if(this.check()){var a=document.createElement("script");a.type="text/javascript";a.src=g;document.body&&document.body.appendChild(a)}};
        this.start=function(){var t=this;"complete"!==document.readyState?window.addEventListener?window.addEventListener("load",function(){t.go()},!1):window.attachEvent&&window.attachEvent("onload",function(){t.go()}):t.go()};};
        try{(new g(100,"r","QSI_S_ZN_2wivzeIdTUNGUmF","https://zn2wivzeidtungumf-nestleglobalmktg.siteintercept.qualtrics.com/SIE/?Q_ZID=ZN_2wivzeIdTUNGUmF")).start()}catch(i){}})();
    `;
    document.body.appendChild(qualtricsScript);

    const qualtricsDiv = document.createElement('div');
    qualtricsDiv.id = target === 'PRO' ? 'ZN_1oGdlwsfhmbnD02' : 'ZN_2wivzeIdTUNGUmF';
    document.body.appendChild(qualtricsDiv);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <ProjectProvider>
          <Authenticator.Provider>
            <SnackbarProvider>
              <QueryClientProvider client={queryClient}>
                <AnalyticsHandler />
                <Layout />
              </QueryClientProvider>
            </SnackbarProvider>
          </Authenticator.Provider>
        </ProjectProvider>
      </HashRouter>
    </ThemeProvider>
  );
};

export default App;
