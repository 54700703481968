import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Button,
  Typography,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import SubjectIcon from '@material-ui/icons/Subject';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import * as XLSX from 'xlsx';
import moment from 'moment';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SearchIcon from '@material-ui/icons/Search';
import MessageCard from '../../components/utils/MessageCard';
import GraphqlService from '../../service/graphqlService';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { StyledTableCell, StyledTableRow, StyledTableHeaderCell } from '../../components/utils/StyledTable';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
    padding: 16,
  },
  button: {
    float: 'right',
    margin: '0 0 16px 24px',
  },
  tableButton: {
    height: 48,
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  searchButton: {
    height: 56,
    borderRadius: 12,
  },
  titleCount: {
    fontWeight: 'bold',
    marginLeft: 8,
  },
}));

export default function NewUsersList() {
  const classes = useStyles();
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedFormattedStartDate, setSelectedFormattedStartDate] = useState(null);
  const [selectedFormattedEndDate, setSelectedFormattedEndDate] = useState(null);

  const {
    data: usersData,
    error: errorFetching,
    isLoading,
    refetch,
  } = useQuery(['newUsers', selectedFormattedStartDate, selectedFormattedEndDate],
    async () => GraphqlService.getNewUsersBetweenDates(
      // send as date
      moment(selectedStartDate),
      moment(selectedEndDate),
    ), {
      enabled: selectedFormattedStartDate !== null && selectedFormattedEndDate !== null,
      cacheTime: 300000, // Cache for 5 minutes
      staleTime: 300000, // Data is considered stale after 5 minutes
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: false,
    });

  const handleChangeStartDate = (date) => {
    if (date === null) {
      setSelectedStartDate(null);
      setSelectedFormattedStartDate(null);
    } else {
      setSelectedStartDate(moment(date));
      const dateFormatted = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
      setSelectedFormattedStartDate(dateFormatted);
    }
  };

  const handleChangeEndDate = (date) => {
    if (date === null) {
      setSelectedEndDate(null);
      setSelectedFormattedEndDate(null);
    } else {
      setSelectedEndDate(moment(date));
      const dateFormatted = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
      setSelectedFormattedEndDate(dateFormatted);
    }
  };

  const setLastDay = () => {
    setSelectedStartDate(moment().subtract(1, 'days'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    refetch();
  };

  const setLastWeek = () => {
    setSelectedStartDate(moment().subtract(7, 'days'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    refetch();
  };

  const setLastMonth = () => {
    setSelectedStartDate(moment().subtract(1, 'months'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    refetch();
  };

  const downloadCsv = () => {
    if (!usersData || !usersData.length) return;

    let csvContent = 'User ID,First Action Date,Email,Cognito Group\n';
    usersData.forEach((user) => {
      const row = `${user.username},${user.createdDate},${user.email},"${user.groups}"`;
      csvContent += `${row}\n`;
    });

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'NewUsersList.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const downloadXls = () => {
    if (!usersData || !usersData.length) return;

    const worksheet = XLSX.utils.json_to_sheet(usersData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    const xlsBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    const blob = new Blob([xlsBuffer], { type: 'application/vnd.ms-excel' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'NewUsersList.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyUsersInClipboard = () => {
    if (!usersData) return;
    const emails = usersData.map((user) => user.email).join(';');
    navigator.clipboard.writeText(emails);
  };

  function renderContent() {
    if (errorFetching) {
      return <MessageCard message={`Error: ${errorFetching.message}`} />;
    }

    if (selectedFormattedStartDate === null || selectedFormattedEndDate === null) {
      return <MessageCard message="Select a date to start the search" />;
    }

    if (isLoading) {
      return (
        <>
          <ProgressDialog open header="Retrieving new users, please wait" />
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>
      );
    }

    if (usersData?.length > 0) {
      return (
        <Grid container direction="column" spacing={2}>
          <div className={classes.tableIcons}>
            <Button
              id="btnCSV"
              type="form"
              startIcon={<GetAppIcon />}
              className={classes.tableButton}
              color="secondary"
              variant="contained"
              onClick={downloadCsv}
            >
              CSV
            </Button>
            <Button
              id="btnXLS"
              type="form"
              startIcon={<SubjectIcon />}
              className={classes.tableButton}
              color="secondary"
              variant="contained"
              onClick={downloadXls}
            >
              XLS
            </Button>
            <Button
              id="btnCopyEmails"
              type="form"
              startIcon={<AssignmentTurnedInIcon />}
              className={classes.tableButton}
              color="secondary"
              variant="contained"
              onClick={copyUsersInClipboard}
            >
              CLIPBOARD EMAILS
            </Button>
          </div>

          <TableContainer component={Paper} className={classes.mainTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell>User ID</StyledTableHeaderCell>
                  <StyledTableHeaderCell>First Action Date</StyledTableHeaderCell>
                  <StyledTableHeaderCell>Email</StyledTableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {usersData.map((user) => (
                  <StyledTableRow key={user.username}>
                    <StyledTableCell>{user.username}</StyledTableCell>
                    <StyledTableCell>{user.createdDate}</StyledTableCell>
                    <StyledTableCell>{user.email}</StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      );
    }

    return <MessageCard message="No new users found for the selected date range" />;
  }

  const titleWithCount = usersData?.length > 0
    ? `${usersData.length} NEW USERS `
    : '';

  return (
    <Box>
      <Typography
        variant="h4"
        component="h1"
        gutterBottom
        style={{
          fontWeight: 'bold',
          margin: '16px 0',
          padding: '0 16px',
        }}
      >
        {titleWithCount}
      </Typography>
      <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">
        <Box display="flex" flexWrap="wrap" alignItems="center">
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              id="inputDateFrom"
              variant="inline"
              orientation="landscape"
              inputVariant="filled"
              label="From"
              value={selectedStartDate}
              format="DD/MM/yyyy"
              onChange={(date) => handleChangeStartDate(date)}
              color="secondary"
              style={{ marginRight: 16 }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardDatePicker
              autoOk
              id="inputDateTo"
              variant="inline"
              orientation="landscape"
              inputVariant="filled"
              label="To"
              value={selectedEndDate}
              format="DD/MM/yyyy"
              onChange={(date) => handleChangeEndDate(date)}
              color="secondary"
              style={{ marginRight: 16 }}
            />
          </MuiPickersUtilsProvider>
          <Button id="btnLastDay" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastDay} style={{ marginLeft: 16 }}> Last Day </Button>
          <Button id="btnLastWeek" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastWeek} style={{ marginLeft: 16 }}> Last Week </Button>
          <Button id="btnLastMonth" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastMonth} style={{ marginLeft: 16 }}> Last Month </Button>
        </Box>
        <div>
          {renderContent()}
        </div>
      </Grid>
    </Box>
  );
}
