/* eslint-disable react/no-children-prop */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Switch, Route, useLocation } from 'react-router-dom';
import {
  Button, Snackbar, Dialog, DialogActions, DialogContent, DialogContentText, Card, CardContent, Typography, Box,
} from '@material-ui/core';
import { useAuthenticator } from '@aws-amplify/ui-react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { Auth, API } from 'aws-amplify';
import { useQueryClient, useQuery } from '@tanstack/react-query';
import Header from './Header';
import LeftBar from './LeftBar';
import routes from '../config/routes';
import SelectedObjectsToDeployContext from '../config/SelectedAssetsContext';
import AuthorizationProvider from '../authorization/AuthorizationProvider';
import Restricted from '../authorization/Restricted';
import LoadingCard from './utils/LoadingCard';
import Login from '../screens/Login';
import Footer from './Footer';
import CookieConsent from './CookieConsent/CookieConsent';
import { target } from '../config/awsExports';
import { onUpdateConfig } from '../config/graphql';
import markdownContent from '../CHANGELOG';
import { useSnackbar } from '../providers/SnackbarContext';
import GraphqlService from '../service/graphqlService';
import useConfig from '../hooks/useGetConfig';
import useRole from '../hooks/useGetRole';
import Maintenance from '../screens/Maintenance';
import { hooks } from '../config/hooksLoader'; // Add this import
import useMaintenanceSubscription from '../hooks/useMaintenanceSubscription';
import getCookieValue from '../utils/getCookieValue';
import NoProjectsPage from './NoProjectsPage';

// Utility function to convert the changelog array to markdown format
const convertChangelogToMarkdown = (changelogData) => {
  if (!Array.isArray(changelogData)) return '';

  let markdown = '# Updates\nAll notable changes to this project will be documented in this page.\n\nStay updated with every version\'s enhancements and optimizations to ensure you\'re making the most out of our application.\n\nThe format is based on [Keep a Changelog](https://keepachangelog.com/en/1.0.0/),\nand this project adheres to [Semantic Versioning](https://semver.org/spec/v2.0.0.html).\n\n';

  changelogData.forEach((entry) => {
    markdown += `## v${entry.version} (${entry.releaseDate})\n\n`;

    if (entry.new && entry.new.length > 0) {
      markdown += '**New**\n';
      entry.new.forEach((item) => {
        markdown += `- **${item.title}:** ${item.description}\n`;
      });
      markdown += '\n';
    }

    if (entry.updated && entry.updated.length > 0) {
      markdown += '**Updated**\n';
      entry.updated.forEach((item) => {
        markdown += `- **${item.title}:** ${item.description}\n`;
      });
      markdown += '\n';
    }

    if (entry.fix && entry.fix.length > 0) {
      markdown += '**Fixed**\n';
      entry.fix.forEach((item) => {
        markdown += `- **${item.title}:** ${item.description}\n`;
      });
      markdown += '\n';
    }
  });

  return markdown;
};

const drawerWidth = 280;

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 'auto',
    marginRight: 'auto',
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: theme.palette.common.white,
    },
  },
  content: {
    backgroundColor: theme.palette.common.white,
    paddingBottom: 56,
  },
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: theme.zIndex.appBar - 1,
    padding: theme.spacing(0),
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
  newHome: {
    backgroundColor: theme.palette.common.white,
    color: 'white',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '6px',
  },
  linkToNewPortal: {
    color: 'white',
    textDecoration: 'none',
    background: 'blue',
    paddingLeft: '4px',
    paddingRight: '4px',
  },
}));

function RoutesComponent({ openSnackBar }) {
  return (
    <Switch>
      {routes.map(({ path: routespath, component: Component, permission }) => (
        <Route
          exact
          path={routespath}
          key={routespath}
          render={(props) => (
            <Restricted
              to={[permission]}
              loadingComponent={<LoadingCard />}
            >
              <Component {...props} openSnackBar={openSnackBar} />
            </Restricted>
          )}
        />
      ))}
    </Switch>
  );
}

function MainComponent({
  open, setOpen, openChangeLogModal, setOpenChangeLogModal, snackBarOpen, setSnackBarOpen, snackBarText,
  setSnackBarText, selectedAssetsProviderValue, handleDrawer, handleOpenChangeLogModal, handleCloseChangeLogModal,
  openSnackBar, permissionsFetched, classes, fetchPermissions, route,
}) {
  const fetchProjects = async () => GraphqlService.getProjects();
  const dialogRef = useRef(null);
  const {
    data: projectsResponse,
    error: projectsError,
    isLoading: projectsLoading,
  } = useQuery(['projectsLayout'], fetchProjects);

  // Convert the changelog array to markdown format
  const changelogMarkdown = convertChangelogToMarkdown(markdownContent);

  return (
    <>
      {route === 'authenticated'
        ? (
          <SelectedObjectsToDeployContext.Provider value={selectedAssetsProviderValue}>
            <AuthorizationProvider fetchPermissions={fetchPermissions}>
              <div className={classes.root}>
                <CssBaseline />
                <main className={classes.content}>
                  <LeftBar open={open} setOpen={setOpen} />
                  <div className={classes.mainContainer}>
                    <Header handleDrawer={handleDrawer} handleOpenChangeLogModal={handleOpenChangeLogModal} projects={projectsResponse} />
                    {permissionsFetched && <RoutesComponent openSnackBar={openSnackBar} />}
                  </div>
                  {(projectsError && (!projectsResponse || !projectsLoading)) && (
                    <Box>
                      <NoProjectsPage />
                    </Box>

                  )}
                  {/* <CookieConsent /> */}
                  <Dialog ref={dialogRef} open={openChangeLogModal} onClose={handleCloseChangeLogModal} aria-labelledby="changelog-title" fullWidth maxWidth="sm">
                    <DialogContent ref={dialogRef}>
                      <DialogContentText id="changelog-text">
                        <Card className={classes.card}>
                          <CardContent>
                            <Typography component="div" variant="body1" className={classes.markdown}>
                              <ReactMarkdown children={changelogMarkdown} rehypePlugins={[rehypeRaw]} />
                            </Typography>
                          </CardContent>
                        </Card>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCloseChangeLogModal} color="primary">
                        Close
                      </Button>
                    </DialogActions>
                  </Dialog>
                  <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                    open={snackBarOpen}
                    autoHideDuration={2000}
                    message={snackBarText}
                    action={(
                      <Button color="inherit" size="small" onClick={() => setSnackBarOpen(false)}>
                        Close
                      </Button>
                    )}
                    onClose={() => setSnackBarOpen(false)}
                    key="snackbar"
                  />
                </main>
                <Footer />
              </div>
            </AuthorizationProvider>
          </SelectedObjectsToDeployContext.Provider>
        )
        : <Login />}
    </>
  );
}

function PortalNewUrlBanner({ classes, newUrlPortal, routeContainsPortal }) {
  return (
    <>
      {target !== 'DEV' && target !== 'STAG' && (!routeContainsPortal) && (
        <div className={classes.newHome}>
          <div>Portal has a new home! Time to update your Bookmarks.</div>
          <div>Click</div>
          <a href={newUrlPortal} className={classes.linkToNewPortal}>here</a>
          <div>to Access the new portal website</div>
        </div>
      )}
    </>
  );
}

function PortalInMaintenanceBannerForRoot({ classes, isInMaintenance, role }) {
  return (
    <>
      {isInMaintenance && role.includes('rootAccess') && (
        <div className={classes.newHome}>
          <div>Portal is being updated</div>
        </div>
      )}
    </>
  );
}

export default function Layout() {
  const classes = useStyles();

  // Default value for Models combo
  const [permissionsFetched, setPermissionsFetched] = useState(false);
  const [selectedObjectsToDeploy, setSelectedObjectsToDeploy] = useState([]);
  const [open, setOpen] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [isInMaintenance, setIsInMaintenance] = useState(false);
  const selectedAssetsProviderValue = { selectedObjectsToDeploy, setSelectedObjectsToDeploy };
  const [openChangeLogModal, setOpenChangeLogModal] = useState(false);
  const openSnackBar = useSnackbar();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { configData } = useConfig();
  useMaintenanceSubscription();
  const subscribeToNotifications = async () => {
    try {
      const subscription = API.graphql({
        query: onUpdateConfig,
        authMode: 'AMAZON_COGNITO_USER_POOLS',
        variables: {
          authToken: Auth.user?.attributes?.sub || Auth.user?.signInUserSession?.accessToken?.payload?.sub,
        },
      }).subscribe({
        next: () => {
          queryClient.invalidateQueries(['config']);
        },
        error: (error) => {
          console.log(`Error subscribing to config update: ${error}`, 'error');
        },
      });
      // Cleanup function to unsubscribe
      return () => subscription.unsubscribe();
    } catch (error) {
      console.log(`Error setting up subscription to config update: ${error}`, 'error');
      return null;
    }
  };

  useEffect(() => {
    subscribeToNotifications();
  }, []);

  const handleDrawer = () => {
    setOpen(!open);
  };

  const handleOpenChangeLogModal = () => {
    setOpenChangeLogModal(true);
  };

  const handleCloseChangeLogModal = () => {
    setOpenChangeLogModal(false);
  };

  const fetchPermissions = async (projectId) => {
    try {
      // Assuming hooks.permissionsEndpoint.getPermissions is the correct function
      const result = await hooks.permissionsEndpoint.getPermissions(projectId);
      setPermissionsFetched(true);
      return result;
    } catch (error) {
      openSnackBar('Error fetching permissions');
      return [];
    }
  };

  const { route } = useAuthenticator((context) => [context.route]);

  const { role } = useRole();

  const cookieRootAccess = getCookieValue('rootAccess') === 'true';

  useEffect(() => {
    setIsInMaintenance(configData?.maintenance === 'true' && !cookieRootAccess);
  }, [configData, cookieRootAccess]);

  // Use the value of route to decide which page to render
  return (
    <>
      <PortalInMaintenanceBannerForRoot classes={classes} isInMaintenance={isInMaintenance} role={role} />
      {(isInMaintenance && role && role !== 'rootAccess' && !cookieRootAccess) ? <Maintenance className={classes.root} /> : (
        <MainComponent
          open={open}
          setOpen={setOpen}
          openChangeLogModal={openChangeLogModal}
          setOpenChangeLogModal={setOpenChangeLogModal}
          snackBarOpen={snackBarOpen}
          setSnackBarOpen={setSnackBarOpen}
          snackBarText={snackBarText}
          setSnackBarText={setSnackBarText}
          selectedAssetsProviderValue={selectedAssetsProviderValue}
          handleDrawer={handleDrawer}
          handleOpenChangeLogModal={handleOpenChangeLogModal}
          handleCloseChangeLogModal={handleCloseChangeLogModal}
          openSnackBar={openSnackBar}
          permissionsFetched={permissionsFetched}
          classes={classes}
          fetchPermissions={fetchPermissions}
          route={route}
        />
      )}
    </>
  );
}
