/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import {
  Box,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableFooter,
  TablePagination,
  TableRow,
  LinearProgress,
  Paper,
  TableHead,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React,
  useEffect,
  useState,
} from 'react';
import BrandObjectListItem from '../../../components/MachineTelemetry/BrandObjectListItem';
import TablePaginationActions from '../../../components/utils/TablePaginationActions';
import SecondaryButton from '../../../components/utils/SecondaryButton';
import {
  getColumnInclude, orderHeader, transformComplexJson, extractKeys, deleteParentfromHeader, completeNullDataAccordingToHeader,
} from '../../../utils/objectTable';
import useCSVExport from '../../../hooks/useCSVExport';
import { StyledTableCell } from '../../../components/utils/StyledTable';

const useStyles = makeStyles((theme) => ({
  headerTable: {
    font: theme.typography.h5.font,
    fontSize: 12,
    color: theme.palette.common.black,
  },
  headerBorderTable: {
    borderBottom: `1.5px solid ${theme.palette.primary.banner}`,
  },
  bottomRightExportButton: {
    paddingRight: 8,
    paddingLeft: 8,
    paddingBottom: 16,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 0,
    width: 138,
  },
  historyTable: {
    borderRadius: 0,
    minWidth: 200,
    whiteSpace: 'nowrap',
    '& tr': {
      height: 48,
      '& td': {
        paddingTop: 0,
        paddingBottom: 0,
        paddingLeft: 20,
        paddingRight: 12,
        height: 48,
      },
    },
  },
  separate: {
    borderRadius: 12,
  },
  separateVoidGrid: {
    flexWrap: 'noWrap',
    borderRadius: '12px !important',
  },
  content: {
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.default,
  },
  contentGrid: {
    paddingBottom: 16,
  },
  cardNoMachineTelemetryText: {
    color: theme.palette.primary.text,
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    textAlign: 'center',
  },
  detailsTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightMedium,
    paddingRight: '5px',
  },
  headingTitle: {
    fontSize: 16,
    fontWeight: theme.typography.fontWeightMedium,
  },
  machineInfoCardPadding16: {
    padding: '16px !important',
  },
  telemetryTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  bottomLeftLegend: {
    paddingTop: 4,
  },
  cardNoMachineTelemetry: {
    borderRadius: 12,
  },
  footerGrid: {
    width: 9,
  },
}));

export default function BrandObjectTable({
  search, dataList, totalCount, filters, loading, dateIni, dateEnd, rangeDates,
  page, setPage, rowsPerPage, object, params, id, pagination, paginationTokens, columnInclude, disableOrderingHeader,
}) {
  const [rawData, setRawData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage || 0);
    search(newPage);
    event.preventDefault();
  };

  // WEBPAGE______________________________
  const classes = useStyles();

  useEffect(() => {
    setRawData(getColumnInclude(dataList, columnInclude));
  }, [dataList]);

  let transformedData = transformComplexJson(rawData);

  const dataHeaderExport = extractKeys(transformedData);

  // delete from the header list all the parents that have some info in some rows and are null in the others
  const headerExport = deleteParentfromHeader(dataHeaderExport);
  // alphabetically
  if (!disableOrderingHeader) orderHeader(headerExport);

  transformedData = transformedData.map(completeNullDataAccordingToHeader(headerExport));

  const {
    exportToCSV, loading: exportLoading,
  } = useCSVExport(
    params, filters, dateIni, dateEnd, rangeDates, object,
  );

  return (
    <Box>
      <Grid
        container
        spacing={0}
        direction="row"
        className={classes.content}
        id="brand-events-table"
      >
        {(rawData !== null) && (Array.isArray(rawData)) && (rawData.length > 0)
          && (
            <Grid
              container
              spacing={0}
              direction="row"
              className={classes.contentGrid}
            >
              <Grid item xs={6} className={classes.bottomLeftLegend}>
                <Typography className={classes.bottomLeftLegend}>Data will be retrieved in real time</Typography>
              </Grid>

              <Grid item xs={6} className={classes.bottomRightExportButton}>
                <span>
                  <SecondaryButton
                    id={`btn${id}Export`}
                    onClick={exportToCSV}
                    disabled={exportLoading}
                  >
                    Export .CSV
                  </SecondaryButton>
                </span>

              </Grid>

              <Grid
                item
                xs={12}
                className={classes.separate}
              >
                <TableContainer component={Paper} className={classes.telemetryTable}>
                  <Table className={classes.historyTable} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow>
                        {headerExport.map((head, index) => <StyledTableCell className={classes.tableHead} id={`header${id}-${index}`}>{head}</StyledTableCell>)}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {transformedData.map((item, i) => (
                        <BrandObjectListItem
                          object={item}
                          id={`cell${id}${i}`}
                        />
                      ))}
                    </TableBody>
                    <Grid className={classes.footerGrid}>
                      <TableFooter>
                        <TableRow key="paginationFooter">
                          {pagination === 'limit&offset'
                            ? (
                              <TablePagination
                                rowsPerPageOptions={[20]}
                                colSpan={10}
                                count={totalCount}
                                rowsPerPage={rowsPerPage || 20}
                                page={page}
                                onPageChange={handleChangePage}
                              />
                            ) : (
                              <TablePagination
                                rowsPerPageOptions={[rowsPerPage]}
                                colSpan={10}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                SelectProps={{
                                  inputProps: { 'aria-label': 'rows per page' },
                                  native: true,
                                }}
                                onPageChange={handleChangePage} // Explicitly pass the prop
                                ActionsComponent={(props) => (
                                  <TablePaginationActions
                                    {...props}
                                    onChangePage={handleChangePage} // Explicitly pass the prop
                                  />
                                )}
                                labelDisplayedRows={({ from, to, count }) => (((count !== -1 && paginationTokens.length > 1) || rawData.length < 100)
                                  ? `${from}-${to + (page * 100)} of ${count + ((paginationTokens?.length - 1) * 100)}`
                                  : `${from}-${to} of more than ${to}`)}
                                count={
                                  paginationTokens?.length > 1 && rawData?.length === rowsPerPage
                                    ? -1
                                    : rawData?.length
                                }
                              />
                            )}
                        </TableRow>
                      </TableFooter>
                    </Grid>
                  </Table>
                  {loading && <LinearProgress />}
                </TableContainer>
              </Grid>
            </Grid>
          )}
        {(rawData !== null) && (Array.isArray(rawData)) && (rawData.length === 0)
          && !loading
          && (
            <Grid
              item
              xs={12}
              className={classes.separateVoidGrid}
            >
              <Card className={classes.cardNoMachineTelemetry}>
                <CardContent className={classes.machineInfoCardPadding16}>
                  <Typography className={classes.cardNoMachineTelemetryText}>
                    {`No ${id} available`}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          )}
      </Grid>
    </Box>
  );
}
BrandObjectTable.propTypes = {
  search: PropTypes.func.isRequired,
  dataList: PropTypes.object,
  totalCount: PropTypes.number,
  filters: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  dateIni: PropTypes.string.isRequired,
  dateEnd: PropTypes.string,
  rangeDates: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  setPage: PropTypes.func.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  object: PropTypes.string.isRequired,
  params: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  pagination: PropTypes.string,
  paginationTokens: PropTypes.array,
  columnInclude: PropTypes.string || PropTypes.array,
  disableOrderingHeader: PropTypes.bool,
};
BrandObjectTable.defaultProps = {
  dataList: [],
  totalCount: 0,
  pagination: 'limit&offset',
  paginationTokens: [],
  columnInclude: '',
  dateEnd: null,
  filters: '',
  disableOrderingHeader: false,
};
