import {
  Box, Grid, LinearProgress, makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import {
  React, useState,
} from 'react';
import { PropTypes } from 'prop-types';
import { useForm } from 'react-hook-form';
import { useProject } from '../../../config/ProjectContext';
import SimpleAlert from '../../../components/utils/SimpleAlert';
import BrandObjectTablePaginated from '../MachineTelemetry/BrandObjectTablePaginated';
import useLifeCycleObjectPaginated from '../DeploymentDetails/useLifeCycleObjectPaginated';
import PaginationComponent from '../../../components/Pagination';
import { calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../../utils/dateUtils';
import DateRangePicker from '../../../components/utils/DateRangePicker';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default function DeviceInfo({ deviceSerial }) {
  const { project } = useProject();

  const [alertOpen, setAlertOpen] = useState(false);

  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-7, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-7, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));

  // Temporary state variables for date range picker
  const [tempStartDate, setTempStartDate] = useState(moment().add(-7, 'days'));
  const [tempStartTime, setTempStartTime] = useState(moment().add(-7, 'days').format('HH:mm'));
  const [tempEndDate, setTempEndDate] = useState(moment());
  const [tempEndTime, setTempEndTime] = useState(moment().format('23:59'));

  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);

  const alertText = '';

  const classes = useStyles();

  const { handleSubmit } = useForm();

  const {
    data, isLoading, error, refetch,
  } = useLifeCycleObjectPaginated(
    'machineassetsdetail',
    project,
    deviceSerial,
    JSON.stringify({}),
    selectedStartDate,
    selectedEndDate,
    selectedStartTime,
    selectedEndTime,
    currentPage,
    pageSize,
  );

  const deploymentData = data?.data;
  const pagination = data?.pagination;

  const handlePageChange = (event, value) => {
    setCurrentPage(value - 1);
  };

  const handleSelectChange = (event) => {
    setPageSize(event.target.value);
  };

  const onSubmit = () => {
    refetch();
    return undefined;
  };

  const handleDateChange = (startDate, endDate) => {
    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const onApplyDateRange = () => {
    // Update the actual date state values used by the hook
    setSelectedStartDate(tempStartDate);
    setSelectedEndDate(tempEndDate);
    setSelectedStartTime(tempStartTime);
    setSelectedEndTime(tempEndTime);
    refetch();
  };

  return (
    <Box spacing={0}>
      {(error || alertText) && <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header="Error" body={error?.message || alertText} />}

      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ padding: 0 }}
      >
        <DateRangePicker
          initialStartDate={new Date()}
          initialEndDate={new Date()}
          onDateChange={handleDateChange}
          setSelectedStartDate={setTempStartDate}
          selectedStartDate={tempStartDate}
          setSelectedStartTime={setTempStartTime}
          selectedStartTime={tempStartTime}
          setSelectedEndDate={setTempEndDate}
          selectedEndDate={tempEndDate}
          setSelectedEndTime={setTempEndTime}
          selectedEndTime={tempEndTime}
          isEndDateEnabled={false}
          onApply={onApplyDateRange}
        />
      </form>
      {isLoading ? <LinearProgress />
        : (
          <Grid item xs={12}>
            <BrandObjectTablePaginated
              dataList={deploymentData}
              loading={isLoading}
              dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
              dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
              object={`machineassetsdetail/${project.code}/${deviceSerial}`}
              params={JSON.stringify({})}
              id="DeploymentDetails"
            />
            <div className={classes.tableIcons}>

              <PaginationComponent
                pageSize={pageSize}
                handleSelectChange={handleSelectChange}
                pagination={pagination}
                handlePageChange={handlePageChange}
              />

            </div>
          </Grid>
        )}
    </Box>
  );
}

DeviceInfo.propTypes = {
  deviceSerial: PropTypes.string.isRequired,
};
