import { useQuery } from '@tanstack/react-query';
import GraphqlService from '../../../service/graphqlService';
import { calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../../utils/dateUtils';

const useLifeCycleObjectPaginated = (
  key,
  project,
  id,
  params,
  startDate,
  endDate,
  startTime,
  endTime,
  page,
  pageSize,
) => {
  const query = useQuery(
    [key, project, params, startDate, endDate, startTime, endTime, page, pageSize],
    async () => {
      const queryParams = { ...params };
      Object.keys(queryParams).forEach((keyParam) => {
        if (queryParams[keyParam] === '' || queryParams[keyParam] === null) {
          delete queryParams[keyParam];
        }
      });
      const response = await GraphqlService.getLifeCycleObjectPaginated(
        project.code,
        `${key}/${project.code}/${id}`,
        JSON.stringify(queryParams),
        {},
        calculateStartDateTimeIso(startDate, startTime),
        calculateEndDateTimeIso(endDate, endTime),
        page,
        pageSize,
      );

      return {
        data: response ? JSON.parse(response.data) : [],
        pagination: response?.pagination,
      };
    },
    {
      enabled: !!project && !!id,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  );

  const { refetch } = query;
  return { ...query, refetch };
};

export default useLifeCycleObjectPaginated;
