/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import {
  Box,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  Paper,
  Switch,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useMutation } from '@tanstack/react-query';
import PageBanner from '../../components/utils/PageBanner';
import GraphqlService from '../../service/graphqlService';
import { useSnackbar } from '../../providers/SnackbarContext';
import useConfig from '../../hooks/useGetConfig';
import { StyledTableCell, StyledTableRow } from '../../components/utils/StyledTable';
import NotificationCenter from './NotificationsCenter';

const useStyles = makeStyles((theme) => ({
  mainBox: {
    margin: 12,
  },
  accordion: {
    backgroundColor: 'white',
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
    backgroundColor: 'white',
  },
  separate: {
    marginBottom: theme.spacing(2),
  },
}));

export default function PortalConfig() {
  const classes = useStyles();
  const { configData } = useConfig();

  const updateConfigMutation = useMutation((variables) => GraphqlService.updateConfig(variables.config));
  const openSnackBar = useSnackbar();

  const [maintenanceMode, setMaintenanceMode] = useState(false);

  useEffect(() => {
    if (configData && configData.maintenance !== undefined) {
      setMaintenanceMode(configData.maintenance === 'true');
    }
  }, [configData]);

  const handleSwitchChange = async (event) => {
    const newValue = event.target.checked;
    try {
      await updateConfigMutation.mutateAsync({
        config: JSON.stringify({
          maintenance: newValue.toString(),
        }),
      });
      openSnackBar('Config Updated', 'info');
    } catch (error) {
      openSnackBar(error.message, 'error');
    }
  };

  return (
    <Box>
      <PageBanner title="CONFIGURATION" />
      <Box className={classes.mainBox}>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordion}>
            <strong>Settings</strong>
          </AccordionSummary>
          <AccordionDetails className={classes.accordion}>
            <Box component={Paper} display="flex" flexDirection="column" alignItems="stretch">
              <Box>
                <TableContainer className={classes.mainTable}>
                  <Table aria-label="simple table">
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="center">Maintenance Mode</StyledTableCell>
                        <StyledTableCell align="center">
                          <Switch
                            checked={maintenanceMode}
                            onChange={handleSwitchChange}
                            name="maintenanceMode"
                            color="primary"
                          />
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </AccordionDetails>
        </Accordion>
        <Accordion defaultExpanded>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordion}>
            <strong>Notification Center</strong>
          </AccordionSummary>
          <AccordionDetails style={{ backgroundColor: 'white' }}>
            <Box component={Paper}>
              <NotificationCenter />
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
}
