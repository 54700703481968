/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid, Link, TextField, makeStyles, InputLabel, RadioGroup, FormControlLabel, Radio, FormLabel,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { React } from 'react';
import { Alert } from '@material-ui/lab';
import CustomTextArea from './CustomTextArea';
import SearchButton from './SearchButton';
import DateRangePicker from './DateRangePicker';

export default function Filters({
  selectedFilters, setSelectedFilters, submitting,
  selectedStartDate, setSelectedStartDate, selectedStartTime,
  setSelectedStartTime, selectedEndDate, setSelectedEndDate,
  selectedEndTime, setSelectedEndTime,
  id,
  datesOptional, messageTypeVisible,
  filtersVisible, deviceSerialVisible,
  linkVisible, linkText, linkURL,
  deviceSerial, setDeviceSerial,
  messageType, setMessageType,
  deviceSerialError, maxDaysBetweenDates, subtitle,
  filtersComponents, setDateRangePickerVisibility,
}) {
  const useStyles = makeStyles((theme) => ({
    topContent: {
      backgroundColor: theme.palette.background.paper,
      padding: 24,
    },
    filterField: {
      paddingTop: 16,
      paddingRight: 12,
    },
    bottomContent: {
      paddingTop: 16,
      paddingBottom: 12,
    },
    topField: {
      minHeight: 76,
    },
    radioGroup: {
      paddingLeft: 12,
      paddingTop: 7,
      width: '100%',
      backgroundColor: 'white',
      height: 54,
      borderRadius: 4,
    },
    radioGrid: {
      marginRight: 8,
      borderTopRightRadius: 4,
      borderTopLeftRadius: 4,

    },
    serialField: {
      marginTop: 26,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    messageField: {
      marginTop: 26,
      [theme.breakpoints.down('sm')]: {
        marginTop: 0,
      },
    },
    picker: {
      '& .MuiIconButton-root': {
        color: 'black',
      },
      width: '100%',
    },
    errorAlert: {
      paddingTop: 0,
      paddingBottom: 0,
      marginBottom: 4,
    },
    bottomRightButton: {
      textAlign: 'right',
      alignSelf: 'flex-end',
      borderRadius: 12,
      width: 138,
    },
    linkClass: {
      paddingTop: 15,
    },
    inputDescriptionLabel: {
      font: theme.typography.h4.font,
      fontSize: 13,
      paddingTop: 10,
      paddingBottom: 6,
    },
    inputSubtitleLabel: {
      font: theme.typography.h4.font,
      color: theme.palette.primary.text,
    },
    datePicker: {
      marginTop: 8,
      marginBottom: 16,
    },
  }));

  const handleDateChange = (startDate, endDate) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
  };

  const classes = useStyles();
  return (
    <Grid
      item
      container
      spacing={2}
      direction="row"
      className={classes.topContent}
    >
      {subtitle !== '' && (
        <Grid item xs={12}>
          <InputLabel
            className={classes.inputSubtitleLabel}
          >
            {subtitle}
          </InputLabel>
        </Grid>
      )}
      {filtersComponents?.length > 0 && (
        <Grid item xs={12} md={3}>
          {filtersComponents.map((component) => (
            component.type === 'radio' && (
              <Grid container display="flex" className={classes.radioGrid}>
                <FormLabel className={classes.inputDescriptionLabel}>{component.label}</FormLabel>
                <RadioGroup
                  className={classes.radioGroup}
                  onChange={component.handleChange}
                  defaultValue={component.defaultValue}
                >
                  <Grid item display="flex" direction="row">
                    {component.values.map((value) => (

                      <FormControlLabel
                        id={`btnRadio${id}${value.label}`}
                        value={value.value}
                        control={<Radio />}
                        label={value.label}
                      />

                    ))}
                  </Grid>
                </RadioGroup>
              </Grid>
            )
          ))}
        </Grid>
      )}
      <Grid item xs={12} md={3} className={classes.datePicker}>

        <DateRangePicker
          initialStartDate={new Date()}
          initialEndDate={new Date()}
          onDateChange={handleDateChange}
          setSelectedStartDate={setSelectedStartDate}
          selectedStartDate={selectedStartDate}
          setSelectedStartTime={setSelectedStartTime}
          selectedStartTime={selectedStartTime}
          setSelectedEndDate={setSelectedEndDate}
          selectedEndDate={selectedEndDate}
          setSelectedEndTime={setSelectedEndTime}
          selectedEndTime={selectedEndTime}
          datesOptional={datesOptional}
          maxDaysBetweenDates={maxDaysBetweenDates}
          setVisibility={setDateRangePickerVisibility}
          isEndDateEnabled={false}
        />
      </Grid>

      {
        deviceSerialVisible && (
          <Grid item container justifyContent="center" xs={12} md={4} className={classes.serialField}>
            <Grid item xs={12}>
              <TextField
                id={`input${id}DeviceSerial`}
                variant="filled"
                label="Device Serial"
                value={deviceSerial}
                onChange={(event) => {
                  if (setDeviceSerial) {
                    setDeviceSerial(event.target.value);
                  }
                }}
                orientation="landscape"
                inputVariant="filled"
                className={classes.picker}
                color="secondary"
              />
              {deviceSerialError && <Alert severity="error" className={classes.errorAlert}>Cannot be empty</Alert>}
            </Grid>
          </Grid>
        )
      }
      {
        messageTypeVisible && (
          <Grid item container justifyContent="center" xs={12} md={2} className={classes.messageField}>
            <Grid item xs={12}>
              <TextField
                id={`input${id}MessageType`}
                variant="filled"
                label="Message type"
                value={messageType}
                onChange={(event) => {
                  setMessageType(event.target.value);
                }}
                orientation="landscape"
                inputVariant="filled"
                className={classes.picker}
                color="secondary"
              />
            </Grid>
          </Grid>
        )
      }

      <Grid item container justifyContent={linkVisible ? 'space-between' : 'flex-end'} xs={12} md={12} className={classes.bottomRightButton}>
        {linkVisible && (
          <Link
            href={linkURL}
            underline="always"
            target="_blank"
            className={classes.linkClass}
          >
            {linkText}
          </Link>
        )}
        <SearchButton
          id={`btn${id}Search`}
          type="submit"
          className={classes.bottomRightButton}
        >
          APPLY
        </SearchButton>
      </Grid>

    </Grid>
  );
}
Filters.propTypes = {
  selectedFilters: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  selectedStartDate: PropTypes.string.isRequired,
  setSelectedStartDate: PropTypes.func.isRequired,
  selectedStartTime: PropTypes.string.isRequired,
  setSelectedStartTime: PropTypes.func.isRequired,
  selectedEndDate: PropTypes.string.isRequired,
  setSelectedEndDate: PropTypes.func.isRequired,
  selectedEndTime: PropTypes.string.isRequired,
  setSelectedEndTime: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  filtersVisible: PropTypes.bool.isRequired,
  deviceSerialVisible: PropTypes.bool.isRequired,
  linkVisible: PropTypes.bool,
  linkText: PropTypes.string,
  linkURL: PropTypes.string,
  messageTypeVisible: PropTypes.bool,
  datesOptional: PropTypes.bool,
  deviceSerial: PropTypes.string,
  setDeviceSerial: PropTypes.func,
  messageType: PropTypes.string,
  setMessageType: PropTypes.func,
  deviceSerialError: PropTypes.bool,
  maxDaysBetweenDates: PropTypes.number,
  subtitle: PropTypes.string,
  filtersComponents: PropTypes.arrayOf(PropTypes.object),
  setDateRangePickerVisibility: PropTypes.bool,
};

Filters.defaultProps = {
  linkVisible: false,
  linkText: '',
  linkURL: '',
  messageTypeVisible: false,
  datesOptional: true,
  deviceSerial: '',
  setDeviceSerial: () => { },
  messageType: '',
  setMessageType: () => { },
  deviceSerialError: false,
  maxDaysBetweenDates: 30,
  subtitle: '',
  filtersComponents: [],
  setDateRangePickerVisibility: false,
};
