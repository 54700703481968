/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
import {
  Box, Grid, makeStyles, LinearProgress,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useState, useEffect,
} from 'react';
import { useForm } from 'react-hook-form';
import { useProject } from '../../../config/ProjectContext';
import GraphqlService from '../../../service/graphqlService';
import SimpleAlert from '../../../components/utils/SimpleAlert';
import useIsDevicePermissionAllowed from '../../../hooks/useIsDevicePermissionAllowed';
import JSONBuilderFilters, { valueTypes } from '../../../components/utils/JSONBuilderFilters';
import BrandObjectTable from '../MachineTelemetry/BrandObjectTable';
import { validate, calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../../utils/dateUtils';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  contentInfo: {
    paddingBottom: 8,
    backgroundColor: theme.palette.background.default,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

const jsonColumns = [

  {
    id: 'id', label: 'ID', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'module_serial', label: 'Module Serial', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'country_code', label: 'Country Code', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'event_code', label: 'Event Code', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'is_valid_time', label: 'Is Valid Time', valueType: valueTypes.BOOLEAN, values: ['true', 'false'],
  },
  {
    id: 'extra', label: 'Extra', valueType: valueTypes.STRING, values: [],
  },
  {
    id: 'created_on_local', label: 'Created On Local', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'created_on_utc', label: 'Created On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'received_on_utc', label: 'Received On UTC', valueType: valueTypes.DATE, values: [],
  },
  {
    id: 'processed_on_utc', label: 'Processed On UTC', valueType: valueTypes.DATE, values: [],
  },
];
export default function BrandEvents({
  deviceSerial, useProcessedTimestamp = false, onNoResults, triggerEventsSearch,
}) {
  const MAX_DAYS_BETWEEN_DATES = 30;
  const params = { add_dict: true };
  const baseParams = { add_dict: true }; // Add missing baseParams definition

  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [rowsPerPageConsumption] = useState(10);
  const [reducedResponse, setReducedResponse] = useState(null);

  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);

  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-7, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-7, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));

  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);

  const [filterError, setFilterError] = useState('');

  const { isAllowed: isDeviceAllowed } = useIsDevicePermissionAllowed(deviceSerial);

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  // FORM_________________________________
  const {
    handleSubmit, register, formState: { errors },
  } = useForm();

  const addDeviceSerialAsFilter = (filters) => {
    try {
      const filtersParsed = JSON.parse(filters || '{ "filter": [] }');
      filtersParsed.filter.push({ column: 'device_serial', operator: 'EQ', value: deviceSerial });
      return JSON.stringify(filtersParsed);
    } catch (err) {
      openAlert('Error addDeviceSerialAsFilter', err.message);
      return '';
    }
  };

  // functions to obtain the data to show
  async function searchObject(newPage) {
    if (!isDeviceAllowed) {
      openAlert('Error', 'You do not have permission to access this device');
      return [];
    }
    const startDate = calculateStartDateTimeIso(selectedStartDate, selectedStartTime);
    const endDate = calculateEndDateTimeIso(selectedEndDate, selectedEndTime);
    setDateRangePickerVisibility(!dateRangePickerVisibility);
    const consumptionResponse = await GraphqlService.getTelemetryObject(
      project.code, 'events', JSON.stringify(params), addDeviceSerialAsFilter(selectedFilters), startDate, endDate, rowsPerPageConsumption, (newPage || 0) * rowsPerPageConsumption || 0, MAX_DAYS_BETWEEN_DATES, useProcessedTimestamp,
    );
    // eslint-disable-next-line no-plusplus
    if (consumptionResponse) {
      if (totalCount < 1 || !newPage) {
        setTotalCount(consumptionResponse.pagination.totalRows);
      }
      return JSON.parse(consumptionResponse.data);
    }
    return [];
  }

  async function search(newPage) {
    try {
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchObject(newPage);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);

      // Check if no results and notify parent if not using processed timestamp
      if (!useProcessedTimestamp && creationResponse.length === 0 && onNoResults) {
        const startDate = calculateStartDateTimeIso(selectedStartDate, selectedStartTime);
        const endDate = calculateEndDateTimeIso(selectedEndDate, selectedEndTime);
        onNoResults(startDate, endDate);
      }

      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  const onSearch = (event, filters) => {
    event.preventDefault();
    if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
      search(0);
    }
  };

  // Effect to trigger search when parent component requests it
  useEffect(() => {
    if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
      search(0);
    }
  }, [triggerEventsSearch]);

  // WEBPAGE______________________________
  const classes = useStyles();

  // Add a function to transform the data according to the specified requirements
  function transformData(data) {
    return data?.map((item) => {
      const extraConcatenated = [item['extra.0'], item['extra.1'], item['extra.UUID']]
        .filter(Boolean)
        .join(' ; ');

      console.log('item =>', item);

      return {
        'Device Timestamp (Local)': item.created_on_local || '-',
        'Device Timestamp (UTC)': item.created_on_utc || '-',
        ...(item.module_serial ? { 'Module Serial': item.module_serial } : {}),
        Market: item.country_code || '-',
        Event: item.dict_event?.code && item.dict_event?.name ? `${item.dict_event?.code} - ${item.dict_event?.name}` : '-',
        'Event Description': item.dict_event?.description ? item.dict_event?.description : '-',
        'Error Type': item.dict_event?.extra?.error_type ? item['dict_event.extra.error_type'] : '-',
        Extra: extraConcatenated || '-',
        'Platform Timestamp (UTC)': item.processed_on_utc || '-',
      };
    });
  }

  // Update the data passed to the BrandObjectTable component
  const transformedData = transformData(reducedResponse || []);

  return (
    <Box spacing={0}>
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />

      <Grid
        item
        xs={12}
      >
        <JSONBuilderFilters
          id="DeviceEvents"
          setSelectedFilters={setSelectedFilters}
          submitting={submitting}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedStartTime={selectedStartTime}
          setSelectedStartTime={setSelectedStartTime}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedEndTime={selectedEndTime}
          setSelectedEndTime={setSelectedEndTime}
          filtersVisible
          deviceSerialVisible={false}
          jsonColumns={jsonColumns}
          setDateRangePickerVisibility={dateRangePickerVisibility}
          onSearch={onSearch}
          setFilterError={setFilterError}
          filterError={filterError}
        />
      </Grid>

      {loading ? <LinearProgress />
        : (
          <Grid
            container
            spacing={0}
          >
            <Grid item xs={12}>
              <BrandObjectTable
                search={(newPage) => search(newPage)}
                dataList={transformedData} // Use transformed data here
                totalCount={totalCount}
                filters={selectedFilters}
                loading={loading}
                dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
                dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
                rangeDates={MAX_DAYS_BETWEEN_DATES}
                page={reducedPage}
                setPage={setReducedPage}
                rowsPerPage={rowsPerPageConsumption}
                object="events"
                params={JSON.stringify(useProcessedTimestamp ? { ...baseParams, use_processed_timestamp: true } : baseParams)}
                id="DeviceEvents"
                setFilterError={setFilterError}
                filterError={filterError}
                disableOrderingHeader
              />
            </Grid>
          </Grid>
        )}
    </Box>
  );
}
BrandEvents.propTypes = {
  deviceSerial: PropTypes.string.isRequired,
  useProcessedTimestamp: PropTypes.bool,
  onNoResults: PropTypes.func,
  triggerEventsSearch: PropTypes.any,
};

BrandEvents.defaultProps = {
  useProcessedTimestamp: false,
  onNoResults: null,
  triggerEventsSearch: null,
};
