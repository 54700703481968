/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import {
  Box, Grid, InputLabel, makeStyles, AppBar, Tabs, Tab, Typography, useTheme,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import * as XLSX from 'xlsx';
import SwipeableViews from 'react-swipeable-views';
import { useProject } from '../../../config/ProjectContext';
import SimpleAlert from '../../utils/SimpleAlert';
import {
  ERROR_DUPLICATED_SERIALS, ERROR_NO_SERIALS, ERROR_SERIALS_LIMIT, DEPLOY_TYPE_SERIAL,
} from '../../../utils/constants';
import CustomTextArea from '../../utils/CustomTextArea';
import TabPanel, { a11yProps } from '../../TabPanel';

const useStyles = makeStyles((theme) => ({
  summaryContent: {
    backgroundColor: theme.palette.background.default,
  },
  dragAndDrop: {
    padding: 12,
  },
  dropZone: {
    border: `2px dashed ${theme.palette.primary.projectPicker}`,
    marginTop: 8,
    height: 100, // Adjust the height as needed
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dropZoneParagraph: {
    fontSize: 14,
    fontWeight: 300,
    textAlign: 'center',
  },
  labelDescription: {
    paddingRight: '24px',
    paddingBottom: '16px',
    color: theme.palette.primary.text,
    fontFamily: theme.typography.h5.fontFamily,
    fontSize: '16px',
    letterSpacing: '0',
    lineHeight: '19px',
  },
  labelDropZone: {
    color: theme.palette.primary.text,
    fontSize: 16,
    lineHeight: '20px',
    paddingLeft: '24px',
    paddingTop: 12,
  },
  labelDropZoneDescription: {
    paddingLeft: '24px',
    paddingTop: 12,
    fontSize: 14,
  },
  gridBackground: {
    backgroundColor: theme.palette.background.form,
    borderRadius: '12px',
  },
  commentGrid: {
    paddingBottom: '22px',
    paddingTop: 15,
  },
  root: {
    flexGrow: 1,
    width: '100%',

  },
}));

export default function MachineDeploy({
  setTargets, setType, setMyFiles, values, setValues, renderMyFiles,
}) {
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  const { project } = useProject();

  // DATA RETRIEVAL __________________________
  async function loadType() {
    setType(DEPLOY_TYPE_SERIAL);
  }

  // WEBPAGE______________________________

  // process CSV data
  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  function validateSerial(serialNumber, position) {
    try {
      console.log('serialNumber', serialNumber);
      if (!serialNumber) {
        openAlert('Error', `Line ${position}: Serial ${serialNumber} is empty`);
        return false;
      }
      if (serialNumber.length === 0) {
        openAlert('Error', `Line ${position}: Serial ${serialNumber} must have at list one character`);
        return false;
      }
      // is a string?
      if (typeof serialNumber !== 'string') {
        openAlert('Error', `Line ${position}: Serial ${serialNumber} is not a string`);
        return false;
      }

      if (!serialNumber.match(/^[0-9a-zA-Z]+$/)) {
        openAlert('Error', `Line ${position}: Serial ${serialNumber} is not alphanumeric`);
        return false;
      }
      return true;
    } catch (error) {
      openAlert('Error validiting Serial');
      return false;
    }
  }

  function hasDuplicates(list) {
    return list.length !== new Set(list).size;
  }

  function processData(rows) {
    if (rows.length === 0) {
      openAlert('Error', ERROR_NO_SERIALS);
      return null;
    }
    if (rows.length > 500) {
      openAlert('Error', ERROR_SERIALS_LIMIT);
      return null;
    }
    const list = [];
    for (let i = 0; i < rows.length; i += 1) {
      const row = rows[i];
      if (!row[0]) {
        openAlert('Error', ERROR_NO_SERIALS);
        return null;
      }
      if (!validateSerial(row[0], i + 1)) {
        return null;
      }
      list.push(row[0]);
    }
    if (hasDuplicates(list)) {
      openAlert('Error', ERROR_DUPLICATED_SERIALS);
      return null;
    }
    return list;
  }

  const handleChangeFile = (files) => {
    const file = files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      /* Parse data */
      const bstr = evt.target.result;
      let wb = null;
      try {
        wb = XLSX.read(bstr, { type: 'binary' });
      } catch (error) {
        openAlert('Error', error.message);
      }
      if (wb != null) {
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const list = processData(XLSX.utils.sheet_to_json(ws, { header: 1 }));
        if (list === null) {
          return;
        }
        setTargets(list);
        setMyFiles(files);
      }
    };
    if (file) reader.readAsBinaryString(file);
  };

  useEffect(() => {
    loadType();
  }, [project]);

  const classes = useStyles();

  const theme = useTheme();

  const [value, setValue] = useState(0);
  const [serialValues, setSerialValues] = useState('');

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleSerialValues = (serialValueParam) => {
    // handle serial values, separated by commas
    const list = serialValueParam.split(',').map((item) => item.trim());
    // delete duplicates and empty spaces
    const uniqueList = [...new Set(list)];
    // give an error if the list has more than 1000 items
    if (uniqueList.length > 1000) {
      openAlert('Error', ERROR_SERIALS_LIMIT);
      return;
    }
    setTargets(uniqueList);
    setSerialValues(serialValueParam);
  };

  const cleanTargetWhenChangeTab = () => {
    setTargets([]);
    setSerialValues('');
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    cleanTargetWhenChangeTab();
  };

  return (
    <Box>
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <Grid
        container
        spacing={0}
        direction="row"
        className={classes.summaryContent}
      >

        <Grid
          container
          className={classes.gridBackground}
        >

          <div className={classes.root}>
            <AppBar position="static" color="default">
              <Tabs
                value={value}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab label="🔠 Serial by text" {...a11yProps(0)} />
                <Tab label="💾 File" {...a11yProps(1)} />

              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
              index={value}
              onChangeIndex={handleChangeIndex}
            >
              <TabPanel value={value} index={0} dir={theme.direction}>
                <Grid container>
                  <Grid item xs={6}>

                    <Grid
                      container
                      className={classes.gridBackground}
                    >
                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZone}>Indicate Device Serial Numbers (max. 500)</InputLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZoneDescription}> Enter the serial numbers separated by commas.</InputLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZoneDescription}>Ex: XXXXX, YYYYY </InputLabel>
                      </Grid>

                    </Grid>
                  </Grid>

                  <Grid
                    item
                    xs={6}
                    spacing={0}
                    direction="column"
                    container
                  >
                    <CustomTextArea
                      screenId="inputDeploymentComments-machineDeployment"
                      placeHolder="Serial Numbers"
                      disableSize
                      values={serialValues}
                      setValues={handleSerialValues}
                      isComment
                      rows={4}
                    />
                  </Grid>
                </Grid>
              </TabPanel>
              <TabPanel value={value} index={1} dir={theme.direction}>
                <Grid container>
                  <Grid item xs={6} className={classes.dragAndDrop}>
                    <DropzoneArea
                      acceptedFiles={['.csv, .xls, .xlsx, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values']}
                      dropzoneText="Click or drag and drop your file here."
                      dropzoneClass={classes.dropZone}
                      dropzoneParagraphClass={classes.dropZoneParagraph}
                      filesLimit={1}
                      showPreviewsInDropzone={false}
                      showAlerts={false}
                      onChange={(files) => handleChangeFile(files)}
                    />
                  </Grid>
                  <Grid item xs={6}>

                    <Grid
                      container
                      className={classes.gridBackground}
                    >
                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZone}>Indicate Device Serial Numbers (max. 500)</InputLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZoneDescription}>✔️ Upload your serial number list in CSV/XLSX format. </InputLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZoneDescription}>✔️ Include a single column with no header.</InputLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZoneDescription}>✔️ List of maximum 500 serial numbers.</InputLabel>
                      </Grid>

                      <Grid item xs={12}>
                        <InputLabel className={classes.labelDropZoneDescription}>✔️ Max filesize allowed = 50 MB</InputLabel>
                      </Grid>

                      {renderMyFiles()}
                    </Grid>
                  </Grid>

                </Grid>
              </TabPanel>

            </SwipeableViews>
          </div>
        </Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          className={classes.commentGrid}
        >
          <Grid
            item
            xs={12}
            spacing={0}
            direction="column"
            container
          >
            <CustomTextArea
              screenId="inputDeploymentComments-machineDeployment"
              title="Deployment description"
              placeHolder="Comments (optional)"
              size={300}
              values={values}
              setValues={setValues}
              isComment
              rows={4}
            />
          </Grid>
        </Grid>

      </Grid>
    </Box>
  );
}

MachineDeploy.propTypes = {
  setTargets: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  setMyFiles: PropTypes.func.isRequired,
  values: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  renderMyFiles: PropTypes.func.isRequired,
};
