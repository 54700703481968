/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Grid, Box, makeStyles,
  Button, TextField, Chip, LinearProgress,
} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import {
  React, useEffect, useState,
} from 'react';
import { useQuery } from '@tanstack/react-query';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import PageBanner from '../../components/utils/PageBanner';
// Local imports
import GraphqlService from '../../service/graphqlService';
import { stringHasValue } from '../../utils/stringHasValue';
import JsonPopup from '../../components/JsonPopup';

export default function CertificateStatus() {
  const useStyles = makeStyles((theme) => ({
    separate: {
      padding: 24,
    },
    container: {
      border: '1px solid #ccc',
      borderRadius: '8px',
      padding: '16px',
      margin: '24px',
      width: 'calc(100% - 64px)',
    },
    searchButton: {
      backgroundColor: theme.palette.primary.button,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
      '&:disabled': {
        color: theme.palette.primary.light,
      },
    },
    clearButton: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
    },
    jsonButton: {
      bottom: '16px',
      right: '16px',
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      padding: '8px 16px',
      borderRadius: 12,
    },
    greenTab: {
      backgroundColor: '#c2fcc2',
      color: 'black',
      width: 150,
    },
    redTab: {
      backgroundColor: '#f5a5a5',
      color: 'black',
      width: 150,
    },
  }));

  const [certificateId, setCertificateId] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [firstPageLoaded, setFirstPageLoaded] = useState(true);

  const getCertificateStatus = async () => GraphqlService.getCertificateStatus(
    certificateId,
  );

  const {
    data: fetchedCertificateStatusData,
    error: errorCertificateStatusData,
    refetch: refetchCertificateStatus,
    isLoading: loadingCertificateStatus,
  } = useQuery(['CertificateStatus'], () => getCertificateStatus(), {
    enabled: false,
    cacheTime: 0,
    retry: 0,
  });

  const classes = useStyles();

  const handleSearchCertificate = () => {
    setCertificateId(certificateId.trim());
    refetchCertificateStatus();
    setShowDetails(true);
    setFirstPageLoaded(false);
  };

  const handleClear = () => {
    setCertificateId('');
    setShowDetails(false);
  };

  const getStatusColor = (status) => {
    if (status === 'ACTIVE') {
      return classes.greenTab;
    }
    if (status === 'INACTIVE') {
      return classes.redTab;
    }
    return classes.regularTab;
  };

  const policiesWithouNull = (policies) => {
    if (policies) {
      return policies.filter((item) => item?.policyName !== null);
    }
    return [];
  };

  return (
    <Box id="certificate-status">
      <PageBanner title="CERTIFICATE STATUS" />

      <Grid container>
        <Grid item xs={12} className={classes.separate}>
          <Box style={{ marginBottom: 28 }}>
            <Typography>
              Check the status of your certificates
            </Typography>
          </Box>

          <Grid container className={classes.filters} spacing={2}>
            <Grid item sm="auto">
              <TextField
                id="certificate-id"
                value={certificateId}
                onChange={(e) => setCertificateId(e.target.value)}
                label="Certificate ID"
                variant="outlined"
                style={{ minWidth: 600 }}
              />
            </Grid>
            <Grid item sm="auto" style={{ alignSelf: 'center' }}>
              <Button
                id="btn-search"
                onClick={handleSearchCertificate}
                className={classes.searchButton}
                variant="contained"
                color="secondary"
                disabled={!stringHasValue(certificateId)}
                startIcon={<SearchIcon />}
              >
                Search
              </Button>
            </Grid>
            <Grid item sm="auto" style={{ alignSelf: 'center' }}>
              <Button
                id="btnClear"
                onClick={handleClear}
                className={classes.clearButton}
                variant="contained"
                startIcon={<RefreshIcon />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {loadingCertificateStatus && !firstPageLoaded && (

        <>
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>

      )}

      {errorCertificateStatusData && showDetails && (
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: 200 }}>
          <Typography>
            We are sorry, but we could not find the certificate with ID you are looking for
          </Typography>
        </Box>
      )}

      {showDetails && fetchedCertificateStatusData && !errorCertificateStatusData && (
        <Grid container className={classes.container}>
          <Grid item xs={12} sm={8} className={classes.separate}>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>
                Certificate ID
              </Typography>
              <Typography>
                {fetchedCertificateStatusData.certificateID}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.separate}>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: 'bold', marginBottom: 2 }}>
                Status
              </Typography>
              <Chip
                label={fetchedCertificateStatusData.status}
                className={getStatusColor(fetchedCertificateStatusData.status)}
              />
            </Box>
          </Grid>

          <Grid item xs={12} className={classes.separate}>
            <Box display="flex" flexDirection="column" id="certificate-arn">
              <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>
                Certificate ARN
              </Typography>
              <Typography>
                {fetchedCertificateStatusData.certificateARN}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} className={classes.separate}>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>
                CA Certificate ID
              </Typography>
              <Typography>
                {fetchedCertificateStatusData.caCertificateID}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4} className={classes.separate}>
            <Box display="flex" flexDirection="column">
              <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>
                Onboarding Status
              </Typography>
              <Typography>
                {fetchedCertificateStatusData.onboardingStatus}
              </Typography>
            </Box>
          </Grid>

          {policiesWithouNull(fetchedCertificateStatusData?.policies)?.length > 0 && (
            <Grid item xs={12} className={classes.separate}>
              <Box display="flex" flexDirection="column">
                <Typography style={{ fontWeight: 'bold', marginBottom: 8 }}>
                  Policies
                </Typography>
                {policiesWithouNull(fetchedCertificateStatusData.policies).map((policy) => (
                  <Typography key={policy.policyArn}>
                    {`${policy.policyName}: ${policy.policyArn}`}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}

          <Grid item xs={12} className={classes.separate}>
            <Box display="flex" justifyContent="flex-end" width="100%">
              <JsonPopup
                title="Asset JSON"
                jsonObject={typeof fetchedCertificateStatusData === 'object' && fetchedCertificateStatusData !== null ? fetchedCertificateStatusData : {}}
              />
            </Box>

          </Grid>

        </Grid>
      )}

    </Box>
  );
}
