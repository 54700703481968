/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState } from 'react';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useQuery } from '@tanstack/react-query';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Button,
  IconButton,
  Tabs,
  Tab,
} from '@material-ui/core';
import * as XLSX from 'xlsx';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import SubjectIcon from '@material-ui/icons/Subject';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import MessageCard from '../../components/utils/MessageCard';
import PageBanner from '../../components/utils/PageBanner';
import GraphqlService from '../../service/graphqlService';
import ProgressDialog from '../../components/utils/ProgressDialog';
import { StyledTableCell, StyledTableRow, StyledTableHeaderCell } from '../../components/utils/StyledTable';
import NewUsersList from './NewUsersList';
import UserActivity from './UserActivity';
import LastUsersAction from './LastUsersAction';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.background.default,
    padding: 16,
  },
  button: {
    float: 'right',
    margin: '0 0 16px 24px',
  },
  tableButton: {
    height: 48,
    justifyContent: 'center',
    margin: 4,
    color: 'white',
    borderRadius: 12,
  },
  tableIcons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingBottom: 16,
    flexWrap: 'wrap',
  },
  mainTable: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
  notificationTitle: {
    flexGrow: 1,
  },
  closeNotificationsButton: {
    float: 'right',
  },
  titleNotifications: {
    padding: 0,
  },
  userActivityButton: {
    marginRight: 16,
    height: 48,
    borderRadius: 12,
  },
  newUsersButton: {
    marginRight: 16,
    height: 48,
    borderRadius: 12,
  },
  loadUsersButton: {
    marginRight: 16,
    height: 48,
    borderRadius: 12,
  },
}));

const getCognitoUsersGroupsCSV = async () => GraphqlService.getCognitoUsersGroupsCSV();

export default function CognitoUsersList() {
  const classes = useStyles();
  const [loadUsers, setLoadUsers] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const {
    data: csvData,
    error: errorFetching,
    isLoading,
  } = useQuery(['cognitoUsers'], getCognitoUsersGroupsCSV, {
    cacheTime: 300000, // Cache for 5 minutes
    staleTime: 300000, // Data is considered stale after 5 minutes
    refetchOnWindowFocus: false, // Disable refetching on window focus
    refetchOnReconnect: false, // Disable refetching on reconnect
    refetchInterval: false, // Disable polling
    enabled: loadUsers, // Only run the query when loadUsers is true
    select: (res) => {
      const rows = res.split('\n').slice(1).map((row) => {
        if (!row) {
          return null;
        }
        const [user, createdDate, email, groups] = row.split(',');
        if (!user || !email) {
          return null;
        }
        const parsedGroups = groups && groups.split(';').map((group) => {
          const parts = group && group.trim().split(':');
          return `${parts[0]}: ${parts[1]}`;
        }).join(', ');
        return {
          user, createdDate, email, groups: parsedGroups,
        };
      });
      return { rows, file: res };
    },
  });

  const getAllUsers = () => {
    const emails = csvData.rows?.filter((row) => row?.email);
    return emails.map((row) => row.email).join(';');
  };

  const downloadCsv = () => {
    const blob = new Blob([csvData.file], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'CognitoUsersList.csv');
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const openUserActivity = (userId) => {
    const url = `${window.location.origin}/#/userActivity/${userId}`;
    window.open(url, '_blank');
  };

  const downloadXls = () => {
    if (!csvData?.rows) {
      return;
    }
    const validData = csvData.rows.filter((row) => row !== null);
    const worksheet = XLSX.utils.json_to_sheet(validData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Users');

    const xlsBuffer = XLSX.write(workbook, { bookType: 'xls', type: 'array' });
    const blob = new Blob([xlsBuffer], { type: 'application/vnd.ms-excel' });

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'CognitoUsersList.xls');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const copyUsersInClipboard = () => {
    const users = getAllUsers();
    navigator.clipboard.writeText(users);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    if (newValue === 2) {
      console.log('Inactive Users');
      setLoadUsers(true);
    }
  };

  function renderContent() {
    if (activeTab === 0) {
      return <NewUsersList />;
    }

    if (activeTab === 1) {
      return (
        <Box>
          <LastUsersAction />
        </Box>
      );
    }

    if (activeTab === 2) {
      if (errorFetching) {
        return <MessageCard message={`Error: ${errorFetching.message}`} />;
      }

      if (isLoading) {
        return (
          <>
            <ProgressDialog open header="Retrieving cognito users, please wait" />
            <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
          </>
        );
      }

      if (csvData?.rows?.length > 0) {
        return (
          <>
            <Grid container direction="column" spacing={2}>
              <div className={classes.tableIcons}>
                <Button id="btnCSV" type="form" startIcon={<GetAppIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={downloadCsv}> CSV </Button>
                <Button id="btnXLS" type="form" startIcon={<SubjectIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={downloadXls}> XLS </Button>
                <Button id="btnSendEmail" type="form" startIcon={<AssignmentTurnedInIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={copyUsersInClipboard}> CLIPBOARD EMAILS </Button>
              </div>

              <TableContainer component={Paper} className={classes.mainTable}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell className={classes.tableHead}>User ID</StyledTableHeaderCell>
                      <StyledTableHeaderCell className={classes.tableHead}>Creation Date</StyledTableHeaderCell>
                      <StyledTableHeaderCell className={classes.tableHead}>Email</StyledTableHeaderCell>
                      <StyledTableHeaderCell className={classes.tableHead}>Activity</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {csvData.rows.map((row) => (row && (
                      <StyledTableRow key={row.user}>
                        <StyledTableCell>{row.user}</StyledTableCell>
                        <StyledTableCell>{row.createdDate}</StyledTableCell>
                        <StyledTableCell>{row.email}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton onClick={() => openUserActivity(row.user)}>
                            <DirectionsRunIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    )))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </>
        );
      }

      return <MessageCard message="Select a date range to start the search" />;
    }

    if (activeTab === 3) {
      return (
        <Box>
          <UserActivity />
        </Box>
      );
    }

    return null;
  }

  return (
    <Box>
      <PageBanner title="USERS" />
      <Grid className={classes.background} container spacing={0} direction="column" alignItems="stretch">
        <Tabs value={activeTab} onChange={handleTabChange} indicatorColor="primary" textColor="primary" centered>
          <Tab label="New Users" />
          <Tab label="Inactive Users" />
          <Tab label="All Users" />
          <Tab label="User Activity" />
        </Tabs>
        <div className={classes.separate}>
          {renderContent()}
        </div>
      </Grid>
    </Box>
  );
}
