/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useQuery } from '@tanstack/react-query';
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Grid,
  LinearProgress,
  makeStyles,
  Button,
  TableFooter,
  TablePagination,
  withStyles,
  TableCell,
} from '@material-ui/core';
import moment from 'moment';
import {
  KeyboardDatePicker, MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SearchIcon from '@material-ui/icons/Search';
import MessageCard from '../../components/utils/MessageCard';

import { ReactComponent as CalendarIcon } from '../../assets/ic_calendar.svg';

// Local imports
import GraphqlService from '../../service/graphqlService';
import ProgressDialog from '../../components/utils/ProgressDialog';
import TablePaginationActions from '../../components/utils/TablePaginationActions';
import { StyledTableRow, StyledTableHeaderCell } from '../../components/utils/StyledTable';

export default function UserActivity() {
  const [selectedStartDate, setSelectedStartDate] = useState(null);
  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [selectedFormattedStartDate, setSelectedFormattedStartDate] = useState(null);
  const [selectedFormattedEndDate, setSelectedFormattedEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [emailFilter, setEmailFilter] = useState('');
  const useStyles = makeStyles((theme) => ({
    background: {
      backgroundColor: theme.palette.background.default,
      padding: 16,
    },
    button: {
      float: 'right',
      margin: '0 0 16px 24px',
    },
    tableButton: {
      height: 48,
      justifyContent: 'center',
      margin: 4,
      color: 'white',
      borderRadius: 12,
    },
    tableIcons: {
      display: 'flex',
      alignItems: 'center',
      paddingBottom: 16,
      flexWrap: 'wrap',
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    mainTable: {
      borderRadius: 12,
      boxShadow: theme.customShadows.noTopShadow,
      width: '100%',
      overflowX: 'auto', // Ensure the table fits within the screen
    },
    footerGrid: {
      width: 9,
    },
    toolbar: {
      width: 350,
    },
  }));

  const StyledTableCell = withStyles(() => ({
    head: {
      backgroundColor: 'rgba(0, 0, 0, 0.4)',
      color: 'white',
    },
    body: {
      fontSize: 14,
      wordBreak: 'break-word', // Ensure long text wraps within the cell
    },
  }))(TableCell);

  const getActionsBetweenDates = async () => {
    const response = await GraphqlService.getActionsBetweenDates({
      from: selectedFormattedStartDate,
      to: selectedFormattedEndDate,
      page: currentPage,
      limit: 10,
      email: emailFilter,
    });
    setCurrentPage(response.pagination?.pageNumber);
    setTotalPages(response.pagination?.totalPages);
    return response.data;
  };

  const {
    data,
    error: errorFetching,
    isLoading,
  } = useQuery(['UserActivity',
    selectedFormattedStartDate,
    selectedFormattedEndDate,
    currentPage,
  ], () => getActionsBetweenDates(), {
    cacheTime: 0,
    enabled: selectedFormattedStartDate !== null && selectedFormattedEndDate !== null,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    refetchOnMount: false,
  });
  const classes = useStyles();

  const handleChangeStartDate = (date) => {
    if (date === null) {
      setSelectedStartDate(null);
      selectedFormattedStartDate(null);
    } else {
      setSelectedStartDate(moment(date));
      const dateFormatted = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
      setSelectedFormattedStartDate(dateFormatted);
    }
  };

  const handleChangeEndDate = (date) => {
    if (date === null) {
      setSelectedEndDate(null);
      selectedFormattedEndDate(null);
    } else {
      setSelectedEndDate(moment(date));
      const dateFormatted = moment(date).format('YYYY-MM-DDTHH:mm:ssZ');
      setSelectedFormattedEndDate(dateFormatted);
    }
  };

  const fetchUserActivity = () => {
    if (selectedFormattedStartDate && selectedFormattedEndDate) {
      getActionsBetweenDates();
    }
  };

  const setLastDay = () => {
    setSelectedStartDate(moment().subtract(1, 'days'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(1, 'days').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    fetchUserActivity();
  };

  const setLastWeek = () => {
    setSelectedStartDate(moment().subtract(7, 'days'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(7, 'days').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    fetchUserActivity();
  };

  const setLastMonth = () => {
    setSelectedStartDate(moment().subtract(1, 'months'));
    setSelectedEndDate(moment());
    setSelectedFormattedStartDate(moment().subtract(1, 'months').format('YYYY-MM-DDTHH:mm:ssZ'));
    setSelectedFormattedEndDate(moment().format('YYYY-MM-DDTHH:mm:ssZ'));
    fetchUserActivity();
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage + 1);
  };

  const handleEmailFilterChange = (event) => {
    setEmailFilter(event.target.value);
  };

  function renderContent() {
    if (errorFetching) {
      return <MessageCard message={`Error: ${errorFetching.message}`} />;
    }

    if (selectedStartDate !== null && selectedEndDate !== null && isLoading) {
      return (
        <>
          <ProgressDialog open header="Retrieving user activity, please wait" />
          <LinearProgress id="linear-progress-id" style={{ width: '100%' }} />
        </>
      );
    }

    if (data) {
      return (

        <Box>

          <TableContainer component={Paper} className={classes.mainTable}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableHeaderCell className={classes.tableHead}>User</StyledTableHeaderCell>
                  <StyledTableHeaderCell className={classes.tableHead}>Email</StyledTableHeaderCell>
                  <StyledTableHeaderCell className={classes.tableHead}>Timestamp</StyledTableHeaderCell>
                  <StyledTableHeaderCell className={classes.tableHead}>Entity Type</StyledTableHeaderCell>
                  <StyledTableHeaderCell className={classes.tableHead}>Action Type</StyledTableHeaderCell>
                  <StyledTableHeaderCell className={classes.tableHead}>Action Detail</StyledTableHeaderCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {data?.map((row) => (row && (
                  <StyledTableRow key={row.id}>
                    <StyledTableCell>{row.user}</StyledTableCell>
                    <StyledTableCell>{row.cognitoEmail}</StyledTableCell>
                    <StyledTableCell>{row.actionTimestamp}</StyledTableCell>
                    <StyledTableCell>{row.entityType}</StyledTableCell>
                    <StyledTableCell>{row.actionType}</StyledTableCell>
                    <StyledTableCell>{row.actionDetails}</StyledTableCell>
                  </StyledTableRow>
                )))}
              </TableBody>
              <Box className={classes.footerGrid}>
                <TableFooter>
                  <TableRow key="paginationFooter">
                    <TablePagination
                      rowsPerPageOptions={[20]}
                      colSpan={10}
                      count={totalPages}
                      rowsPerPage={10}
                      page={currentPage - 1}
                      classes={{ toolbar: classes.toolbar }}
                      SelectProps={{
                        inputProps: { 'aria-label': 'rows per page' },
                        native: true,
                      }}
                      onChangePage={handleChangePage}
                      ActionsComponent={(props) => (
                        <TablePaginationActions
                          {...props}
                          onChangePage={handleChangePage}
                        />
                      )}
                    />
                  </TableRow>
                </TableFooter>
              </Box>
            </Table>
          </TableContainer>
        </Box>

      );
    }

    return <MessageCard message="Select a date range to start the search" />;
  }

  return (
    <Box>
      <Grid
        className={classes.background}
        container
        spacing={0}
        direction="column"
        alignItems="stretch"
      >

        <Grid container direction="row" spacing={2}>
          <div className={classes.tableIcons}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                id="inputDateFrom"
                variant="inline"
                orientation="landscape"
                inputVariant="filled"
                label="From"
                value={selectedStartDate}
                format="DD/MM/yyyy"
                onChange={(date) => handleChangeStartDate(date)}
                color="secondary"
                keyboardIcon={<CalendarIcon />}
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                autoOk
                id="inputDateTo"
                variant="inline"
                orientation="landscape"
                inputVariant="filled"
                label="To"
                value={selectedEndDate}
                format="DD/MM/yyyy"
                onChange={(date) => handleChangeEndDate(date)}
                color="secondary"
                keyboardIcon={<CalendarIcon />}
              />
            </MuiPickersUtilsProvider>
            <TextField
              id="emailFilter"
              label="Filter by email"
              variant="filled"
              value={emailFilter}
              onChange={handleEmailFilterChange}
              style={{ marginLeft: 16 }}
            />
            <Button id="btnLastDay" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastDay}> Last Day </Button>
            <Button id="btnLastWeek" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastWeek}> Last Week </Button>
            <Button id="btnLastMonth" type="form" startIcon={<SearchIcon />} className={classes.tableButton} color="secondary" variant="contained" onClick={setLastMonth}> Last Month </Button>
          </div>
        </Grid>
        {renderContent()}

      </Grid>
    </Box>
  );
}
