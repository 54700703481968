/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-indent */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Box, Grid, makeStyles, LinearProgress,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState,
} from 'react';
import { useProject } from '../../../config/ProjectContext';
import GraphqlService from '../../../service/graphqlService';
import SimpleAlert from '../../../components/utils/SimpleAlert';
import BrandConsumptionsTable from '../MachineTelemetry/BrandConsumptionsTable';
import { validate, calculateEndDateTimeIso, calculateStartDateTimeIso } from '../../../utils/dateUtils';
import JSONBuilderFilters, { valueTypes } from '../../../components/utils/JSONBuilderFilters';
import FineGrainedPermissionsInfo from '../../Admin/PlatformHealth/FineGrainedPermissions/FineGrainedPermissionsInfo';
import useIsDevicePermissionAllowed from '../../../hooks/useIsDevicePermissionAllowed';

const useStyles = makeStyles((theme) => ({
  formField: {
    width: '100%',
    '& ::placeholder': {
      paddingLeft: 8,
      paddingTop: 8,
    },
  },
  formPlaceholder: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      color: 'blue',
    },
  },
  inputDescriptionLabel: {
    font: theme.typography.h4.font,
    color: theme.palette.primary.text,
  },
  topContent: {
    padding: 24,
  },
  contentInfo: {
    paddingTop: 24,
    paddingBottom: 8,
    paddingLeft: 24,
    paddingRight: 24,
    backgroundColor: theme.palette.background.default,
  },
  topField: {
    minHeight: 76,
  },
  bottomLeftFields: {
    paddingLeft: 24,
  },
  bottomRightFields: {
    paddingLeft: 24,
  },
  errorAlert: {
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 4,
  },
  bottomRightButton: {
    paddingTop: 32,
    textAlign: 'right',
    alignSelf: 'flex-end',
    borderRadius: 12,
    width: 138,
  },
  searchIcon: {
    marginRight: '16px',
  },
  picker: {
    '& .MuiIconButton-root': {
      color: 'black',
    },
    width: '100%',
  },
  bottomContent: {
    paddingTop: 16,
    paddingBottom: 12,
  },
  // tabs
  headerTabPanel: {
    backgroundColor: theme.palette.background.paper,
  },
  labelHeaderTabPanel: {
    color: theme.palette.primary.labelHeader,
    fontFamily: theme.typography.h3.fontFamily,
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightMedium,
    letterSpacing: '0',
    lineHeight: '16px',
    textAlign: 'center',
    width: '720px',
    height: '48px',
  },
  detailsTitle: {
    paddingRight: '5px',
    fontFamily: theme.typography.h5.fontFamily,
    lineHeight: theme.typography.h5.lineHeight,
    letterSpacing: theme.typography.h5.letterSpacing,
    fontSize: theme.typography.h5.fontSize,
    color: theme.palette.primary.subMain,
  },
  accordion: {
    borderRadius: '12px !important',
  },
  details: {
    paddingLeft: 24,
    paddingRight: 24,
  },
}));

export default function BrandConsumptions({
  deviceSerial, useProcessedTimestamp = false, onNoResults, triggerConsumptionsSearch,
}) {
  const MAX_DAYS_BETWEEN_DATES = 30;
  // STATE________________________________
  const { project } = useProject();

  // grid
  const [totalCount, setTotalCount] = useState(0);
  const [reducedPage, setReducedPage] = useState(0);
  const [rowsPerPageConsumption] = useState(10);
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');
  //  state values
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  // errors
  const [dateStartError, setDateStartError] = useState(false);
  const [dateEndError, setDateEndError] = useState(false);
  const [greaterSmallerDateError, setGreaterSmallerDateError] = useState(false);
  const [moreThanMaxDaysDateError, setMoreThanMaxDaysDateError] = useState(false);
  const [filterError, setFilterError] = useState('');

  // data
  const [selectedFilters, setSelectedFilters] = useState('');
  const [selectedStartDate, setSelectedStartDate] = useState(moment().add(-29, 'days'));
  const [selectedStartTime, setSelectedStartTime] = useState(moment().add(-29, 'days').format('HH:mm'));
  const [selectedEndDate, setSelectedEndDate] = useState(moment());
  const [selectedEndTime, setSelectedEndTime] = useState(moment().format('23:59'));
  const [dateRangePickerVisibility, setDateRangePickerVisibility] = useState(true);
  // progress dialog
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [reducedResponse, setReducedResponse] = useState(null);

  const { isAllowed: isDeviceAllowed } = useIsDevicePermissionAllowed(deviceSerial);

  // create a function to validate that if the role is developer, only max 10 serials are allowed
  // the filter with column device serial and operator is in is selected by default
  // and the filter can have a max of 10 serials

  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  const jsonColumns = [
    {
      id: 'id', label: 'ID', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'module_serial', label: 'Module Serial', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'country_code', label: 'Country Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'recipe_code', label: 'Recipe Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'product_code', label: 'Product Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'customer_code', label: 'Customer Code', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'is_valid_time', label: 'Is Valid Time', valueType: valueTypes.BOOLEAN, values: ['true', 'false'],
    },
    {
      id: 'extra', label: 'Extra', valueType: valueTypes.STRING, values: [],
    },
    {
      id: 'created_on_local', label: 'Created On Local', valueType: valueTypes.DATE, values: [],
    },
    {
      id: 'created_on_utc', label: 'Created On UTC', valueType: valueTypes.DATE, values: [],
    },
    {
      id: 'received_on_utc', label: 'Received On UTC', valueType: valueTypes.DATE, values: [],
    },
    {
      id: 'processed_on_utc', label: 'Processed On UTC', valueType: valueTypes.DATE, values: [],
    },
  ];

  const addDeviceSerialAsFilter = (filters) => {
    const filtersParsed = JSON.parse(filters || '{ "filter": [] }');
    filtersParsed.filter.push({ column: 'device_serial', operator: 'EQ', value: deviceSerial });
    return JSON.stringify(filtersParsed);
  };

  async function searchConsumption(newPage) {
    if (!isDeviceAllowed) {
      openAlert('Error', 'You do not have permission to access this device');
      return [];
    }
    const startDate = calculateStartDateTimeIso(selectedStartDate, selectedStartTime);
    const endDate = calculateEndDateTimeIso(selectedEndDate, selectedEndTime);
    const consumptionResponse = await GraphqlService.getBrandConsumptions(
      project.code, addDeviceSerialAsFilter(selectedFilters), startDate, endDate, rowsPerPageConsumption, (newPage || 0) * rowsPerPageConsumption, useProcessedTimestamp,
    );

    // map the result for each call in a unique response
    // eslint-disable-next-line no-plusplus
    if (consumptionResponse) {
      if (totalCount < 1 || !newPage) {
        setTotalCount(consumptionResponse.pagination.totalRows);
      }
      return consumptionResponse.brandConsumptions;
    }
    return [];
  }

  async function search(newPage) {
    try {
      setDateRangePickerVisibility(!dateRangePickerVisibility);
      setSubmitting(true);
      setProgressDialogOpen(true);
      setLoading(true);
      let creationResponse = [];

      setReducedPage(newPage || 0);
      creationResponse = await searchConsumption(newPage);
      setReducedResponse(creationResponse);
      setMoreThanMaxDaysDateError(false);

      setSubmitting(false);
      setProgressDialogOpen(false);
      setLoading(false);

      // Check if no results and notify parent if not using processed timestamp
      if (!useProcessedTimestamp && creationResponse.length === 0 && onNoResults) {
        const startDate = calculateStartDateTimeIso(selectedStartDate, selectedStartTime);
        const endDate = calculateEndDateTimeIso(selectedEndDate, selectedEndTime);
        onNoResults(startDate, endDate);
      }

      return creationResponse;
    } catch (error) {
      console.log('Error!!!', error);
      setProgressDialogOpen(false);
      setSubmitting(false);
      setLoading(false);
      openAlert('Error', error.message);
      return null;
    }
  }

  // Watch for triggerSearch changes from parent
  useEffect(() => {
    if (triggerConsumptionsSearch) {
      if (validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) {
        search(0);
      }
    }
  }, [triggerConsumptionsSearch]);

  // WEBPAGE______________________________
  const classes = useStyles();

  const onSearch = (event) => {
    event.preventDefault();
    if (!validate(selectedStartDate, selectedEndDate, selectedStartTime, selectedEndTime, MAX_DAYS_BETWEEN_DATES, setDateStartError, setDateEndError, setSelectedStartDate, setSelectedStartTime, setSelectedEndDate, setSelectedEndTime, setGreaterSmallerDateError, setMoreThanMaxDaysDateError)) return;
    search(0);
  };

  return (
    <Box spacing={0}>
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <FineGrainedPermissionsInfo
        open={false}
        onClose={() => { }}
        style={{ margin: 24 }}
      />

      <div className={classes.content}>
        <JSONBuilderFilters
          id="Consumptions"
          setSelectedFilters={setSelectedFilters}
          selectedStartDate={selectedStartDate}
          setSelectedStartDate={setSelectedStartDate}
          selectedStartTime={selectedStartTime}
          setSelectedStartTime={setSelectedStartTime}
          selectedEndDate={selectedEndDate}
          setSelectedEndDate={setSelectedEndDate}
          selectedEndTime={selectedEndTime}
          setSelectedEndTime={setSelectedEndTime}
          filtersVisible
          deviceSerialVisible={false}
          jsonColumns={jsonColumns}
          setDateRangePickerVisibility={dateRangePickerVisibility}
          setFilterError={setFilterError}
          filterError={filterError}
          onSearch={onSearch}
          showNoEndateCheckbox={false}
        />
      </div>
      {
        loading ? (<LinearProgress />)
          : (

            <Grid>
              <BrandConsumptionsTable
                search={(newPage) => search(newPage)}
                dataList={reducedResponse}
                totalCount={totalCount}
                filters={selectedFilters}
                loading={loading}
                dateIni={calculateStartDateTimeIso(selectedStartDate, selectedStartTime)}
                dateEnd={calculateEndDateTimeIso(selectedEndDate, selectedEndTime)}
                page={reducedPage}
                setPage={setReducedPage}
                rowsPerPage={rowsPerPageConsumption}
              />
            </Grid>

          )
      }

    </Box>
  );
}

BrandConsumptions.propTypes = {
  deviceSerial: PropTypes.string.isRequired,
  useProcessedTimestamp: PropTypes.bool,
  onNoResults: PropTypes.func,
  triggerConsumptionsSearch: PropTypes.any,
};

BrandConsumptions.defaultProps = {
  useProcessedTimestamp: false,
  onNoResults: null,
  triggerConsumptionsSearch: null,
};
