/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-console */
import {
  Box, Grid, InputLabel, makeStyles, Button,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import {
  React, useEffect, useState, useContext,
} from 'react';
// eslint-disable-next-line import/no-named-default
import { default as logo } from '../../../assets/ic_import.svg';
import { useProject } from '../../../config/ProjectContext';
import SimpleAlert from '../../utils/SimpleAlert';
import {
  ERROR_DUPLICATED_SERIALS, ERROR_NO_SERIALS, ERROR_SERIALS_LIMIT, DEPLOY_TYPE_SERIAL, DEPLOY_TYPE_REGEX,
} from '../../../utils/constants';
import CustomTextArea from '../../utils/CustomTextArea';

const useStyles = makeStyles((theme) => ({
  summaryContent: {
    backgroundColor: theme.palette.background.default,
  },
  gridBackground: {
    backgroundColor: theme.palette.background.form,
    borderRadius: '12px',
  },
  commentGrid: {
    paddingBottom: '22px',
  },
  regexGrid: {
    paddingBottom: '16px',
    paddingTop: 15,
    marginLeft: 24,
    marginRight: 24,
  },
}));

export default function RegexDeploy({
  setTargets, setType, values, setValues, regex, setRegex,
}) {
  //  alert data
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertHeader, setAlertHeader] = useState('');
  const [alertText, setAlertText] = useState('');

  const { project } = useProject();

  // DATA RETRIEVAL __________________________
  async function loadType() {
    setType(DEPLOY_TYPE_REGEX);
  }

  // WEBPAGE______________________________
  // process CSV data
  function openAlert(header, text) {
    setAlertHeader(header);
    setAlertText(text);
    setAlertOpen(true);
  }

  useEffect(() => {
    loadType();
  }, [project]);

  useEffect(() => {
    if (regex.length > 0) {
      const regexList = [];
      regexList.push(regex);
      setTargets(regexList);
    }
  }, [regex]);

  const classes = useStyles();

  return (
    <Box>
      <SimpleAlert open={alertOpen} setOpen={setAlertOpen} header={alertHeader} body={alertText} />
      <Grid
        container
        spacing={0}
        direction="row"
        className={classes.summaryContent}
      >
        <Grid
          container
          className={classes.gridBackground}
        >
          <Grid item xs={12} className={classes.regexGrid}>
            <CustomTextArea
              screenId="inputDeploymentRegex"
              title="Regular expression"
              placeHolder="Regex"
              size={300}
              values={regex}
              setValues={setRegex}
              isComment={false}
              rows={4}
            />
          </Grid>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            className={classes.commentGrid}
          >
            <Grid
              item
              xs={12}
              spacing={0}
              direction="column"
              container
            >
              <CustomTextArea
                screenId="inputDeploymentComments-regexDeployment"
                title="Deployment description"
                placeHolder="Comments (optional)"
                size={300}
                values={values}
                setValues={setValues}
                isComment
                rows={4}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

RegexDeploy.propTypes = {
  setTargets: PropTypes.func.isRequired,
  setType: PropTypes.func.isRequired,
  values: PropTypes.string.isRequired,
  setValues: PropTypes.func.isRequired,
  regex: PropTypes.string.isRequired,
  setRegex: PropTypes.func.isRequired,
};
