/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import FilterListIcon from '@material-ui/icons/FilterList';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import GraphqlService from '../../../../service/graphqlService';
import { useProject } from '../../../../config/ProjectContext';
import PageBanner from '../../../../components/utils/PageBanner';
import hasToValidateRole from '../../../../utils/hasToValidateRole';

const useStyles = makeStyles((theme) => ({
  separate: {
    padding: 24,
  },
  table: {
    borderRadius: 12,
    boxShadow: theme.customShadows.noTopShadow,
  },
}));

export default function FineGrainedPermissionsInfo({ style }) {
  const classes = useStyles();
  const { project: { code, role } } = useProject();

  const [searchDevice, setSearchDevice] = useState('');
  const [searchOpen, setSearchOpen] = useState(false);
  const [open, setOpen] = useState(false);

  const getDevicesPermissionsByProject = async () => GraphqlService.getDevicesPermissionsByProject(code);

  const {
    data: devicePermissions,
  } = useQuery(['devicesPermissions'], () => getDevicesPermissionsByProject(), {});

  const handleSearchToggle = () => {
    setSearchOpen(!searchOpen);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (hasToValidateRole(role) && devicePermissions && !devicePermissions.includes('*')
    ? (
      <div
        style={{
          ...style,
        }}
      >
        <Typography
          variant="body1"
          style={{
            color: 'gray',
          }}
        >
          * As a developer, you have access to
          <Typography
            component="span"
            onClick={handleOpen}
            style={{
              cursor: 'pointer', textDecoration: 'underline', color: 'blue', marginLeft: '4px',
            }}
          >
            specific devices.
          </Typography>
          .
        </Typography>
        <Typography
          variant="body1"
          style={{
            color: 'gray',
          }}
        >
          You will not be able to see the devices you do not have access to.
        </Typography>
        <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
          <DialogContent>
            <PageBanner title="DEVICES PERMISSIONS" />
            <p style={{ marginLeft: '24px', marginTop: '24px' }}>
              As a developer, you have access to the following devices
            </p>
            <Grid item xs={12} className={classes.separate}>
              <TableContainer component={Paper} className={classes.table}>
                <Table aria-label="fine grained permissions table">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                          <Typography variant="h3">DEVICES</Typography>
                          <Box display="flex" alignItems="center">
                            {searchOpen && (
                              <TextField
                                label="Search Device"
                                value={searchDevice}
                                onChange={(e) => setSearchDevice(e.target.value)}
                                variant="outlined"
                                size="small"
                                style={{ marginRight: '8px' }}
                              />
                            )}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={handleSearchToggle}
                              startIcon={<FilterListIcon />}
                            >
                              {searchOpen ? 'Close Search' : 'Search'}
                            </Button>
                          </Box>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {devicePermissions
                      ?.filter((device) => device.includes(searchDevice))
                      .map((device) => (
                        <TableRow key={device}>
                          <TableCell>{device}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    ) : null);
}

FineGrainedPermissionsInfo.propTypes = {
  style: PropTypes.object,
};

FineGrainedPermissionsInfo.defaultProps = {
  style: {},
};
